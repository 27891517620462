<template>
  <div class="p-4 flex flex-col gap-y-8">
    <h1 class="text-2xl font-bold">Settings</h1>
    <button
      class="bg-red-500 text-white px-4 py-2 rounded-full font-bold"
      @click="resetLocalStorage"
    >
      Reset Local Storage
    </button>

    <div
      class="border p-4 flex flex-col gap-y-4 divide-y"
      v-for="store in stores"
    >
      <div v-if="store?.$id" class="flex justify-between items-center">
        <div class="font-bold">Store ID: {{ store.$id }}</div>
      </div>
      <div v-if="store?.storyVersions" class="pt-4">
        Story Versions:
        {{
          Object.keys(store?.storyVersions).join(", ") ||
          "No story versions available"
        }}
      </div>
      <div v-if="['wg2'].includes(store?.$id)" class="pt-4">
        <pre class="whitespace-pre-wrap break-words">
OnboardingCompleted={{ store.getOnboardingCompleted }}</pre
        >
        <pre class="whitespace-pre-wrap break-words">
Journey={{ store.getJourney }}</pre
        >
        <pre
          class="whitespace-pre-wrap break-words"
          :data-jua-user-public-id="store.getUser?.public_id"
        >
User={{ store.getUser }}</pre
        >
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue"

const stores = ref({})

const store = useStore()
const wg2 = useWg2Store()

onMounted(async () => {
  document.body.style.overflow = "scroll"

  const ap = getActivePinia()
  if (ap) {
    ap._s.forEach((store) => {
      stores.value = { [store.$id]: store, ...stores.value }
    })
  }
})

const resetLocalStorage = () => {
  const yes = confirm(
    "Are you sure you want to reset local storage? Story version cache will be cleared and all data not saved to the API will be deleted."
  )
  if (yes) {
    localStorage.clear()
    location.reload()
  }
}
</script>
