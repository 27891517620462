<template>
  <div class="p-4">
    <div v-if="isSubmitted">
      <div class="flex flex-col gap-y-4 text-center">
        <h1 class="text-2xl font-bold text-center mb-2">
          Thank you! Your message has been sent successfully.
        </h1>
        <h1 class="text-2xl font-bold text-center mb-8">
          This is the report ID:
        </h1>
        <h1
          class="text-8xl font-bold font-mono text-wg2-red text-center mb-8 p-4"
        >
          {{ bugReportPublicId }}
        </h1>
        <a
          href="/de/wg2/"
          class="bg-wg2-red text-white px-4 py-2 rounded-full font-bold"
        >
          Click here to go back
        </a>
      </div>
    </div>
    <div v-else-if="isLoading">Loading...</div>
    <div v-else class="flex flex-col gap-y-4">
      <h1 class="text-2xl font-bold">Select a tag to report an issue:</h1>

      <div class="flex flex-col gap-y-2">
        <div v-for="t in tags">
          <button
            class="transition border-wg2-red border-2 px-4 py-1 rounded-full font-bold"
            :class="t === tag ? 'text-white bg-wg2-red' : 'text-wg2-red '"
            @click="() => setTag(t)"
          >
            {{ t }}
          </button>
        </div>
      </div>

      <Transition name="fade">
        <div v-if="tag" class="flex flex-col gap-y-4">
          <div class="text-sm">
            Please describe the issue. What is working, what is not working? How
            to reproduce the issue?
          </div>
          <div class="text-sm">
            Note: We will also collect data about the device (such as the
            operating system and version, as well as the journey id and user
            id).
          </div>
          <textarea
            class="border p-4 w-full h-60"
            v-model="message"
            placeholder="Enter a description of the issue here..."
          />
          <button
            class="bg-wg2-red text-white px-4 py-2 rounded-full font-bold"
            @click="submitGenericBugReport"
          >
            Submit Message
          </button>
        </div>
      </Transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import { post } from "@/api/main"
import { ref } from "vue"

const route = useRoute()
const router = useRouter()

const setTag = (t: string) => (tag.value = t)

const tags = [
  "problem_with_photos",
  "problem_with_scanning_qr_codes",
  "problem_with_installation_interaction",
  "something_else",
]
const tag = ref("")
const message = ref("")
const isSubmitted = ref(false)
const isLoading = ref(true)
const deviceInfo = ref({})
const bugReportPublicId = ref("")

const bugReportQrCode = ref(route.params.bugReportQrCode as string)
const internalQrCodeScanner = ref(route.params.internalQrCodeScanner as string)

const submitGenericBugReport = async () => {
  await submitBugReport("debug_00")
}

const submitBugReport = async (kind: string) => {
  const res = await post.bugReport(
    kind,
    deviceInfo.value,
    message.value,
    tag.value
  )
  if (res.public_id) {
    bugReportPublicId.value = res.public_id
  }
  isSubmitted.value = true
}

const getLocalStorageItem = (key: string) => {
  try {
    const value = localStorage.getItem(key)

    if (value !== null) {
      return JSON.parse(value)
    } else {
      return "N/A"
    }
  } catch (error) {
    return "N/A"
  }
}

const getLocalStorageData = () => ({
  wg2: {
    journey: getLocalStorageItem("wg2/journey"),
    user: getLocalStorageItem("wg2/user"),
  },
})

onMounted(async () => {
  const localStorageData = getLocalStorageData()

  if (internalQrCodeScanner.value === "") {
    internalQrCodeScanner.value = "0"
  }

  deviceInfo.value = {
    user_agent: navigator?.userAgent || "N/A",
    local_storage: localStorageData,
    internal_qr_code_scanner: internalQrCodeScanner || "0",
  }
  document.body.style.overflow = "scroll"
  if (bugReportQrCode.value === "debug_00") {
    // we show the generic form to report an unspecified issue via a form
    isLoading.value = false
  } else {
    // else, we automatically send the debug message to the API to be processed
    await submitBugReport(bugReportQrCode.value)
  }
})
</script>
