<template>
  <div class="pulsedot"></div>
</template>

<script setup lang="ts">
// import { Vue3Lottie } from "vue3-lottie"
// import RedSpinnerJSON from "@/assets/projects/wg2/animations/red-spinner.json"

import { ref, onMounted } from "vue"

import { get } from "@/api/main"
import { StoryVersion, Scanpoint } from "@/api/types"

const route = useRoute()
const router = useRouter()
const store = useStore()

const scanpointPublicId = ref(route.params.scanpointPublicId as string)
const locale = ref(route.params.locale as string)

const getScanpoint = async () => {
  const scanpoint = await get.scanpoint(scanpointPublicId.value)
  if (scanpoint.published_story_version.data.story.scenes?.length) {
    return scanpoint
  }
}

const getStoryVersionFromScanpoint = async (scanpoint: Scanpoint) => {
  if (scanpoint.published_story_version.data.story.scenes?.length) {
    return scanpoint.published_story_version
  }
}

const getDefaultLocale = (storyVersion: StoryVersion) => {
  if (storyVersion.data.story.default_locale) {
    return storyVersion.data.story.default_locale as string
  }
  return "en"
}

const getProjectShortId = (storyVersion: StoryVersion) => {
  if (storyVersion.data.project.short_id) {
    return storyVersion.data.project.short_id as string
  }
  return "default"
}

onMounted(async () => {
  document.body.style.overflow = "scroll"

  const scanpoint = await getScanpoint()
  if (scanpoint) {
    const storyVersion = await getStoryVersionFromScanpoint(scanpoint)
    if (storyVersion) {
      await store.setStoryVersion(storyVersion)
      const sceneId = scanpoint?.scene_id
      const defaultLocale = getDefaultLocale(storyVersion)
      const projectShortId = getProjectShortId(storyVersion)
      if (sceneId) {
        router.push({
          name: "scene",
          params: {
            locale: locale.value || defaultLocale,
            projectShortId,
            storyVersionPublicId: storyVersion.public_id,
            sceneId,
          },
        })
      } else {
        throw new Error("Missing sceneId")
      }
    } else {
      throw new Error("Missing storyVersion")
    }
  } else {
    throw new Error("Missing scanpoint")
  }
})
</script>
