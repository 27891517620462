export const errorRecoveryMessages = {
  en: {
    try_to_switch_camera: "Try to switch camera",
    try_to_reload: "Try to reload",
  },
  de: {
    try_to_switch_camera: "Kamera wechseln",
    try_to_reload: "Neu laden",
  },
  fr: {
    try_to_switch_camera: "Changer la caméra",
    try_to_reload: "Recharger",
  },
  it: {
    try_to_switch_camera: "Cambiare la telecamera",
    try_to_reload: "Prova a ricaricare",
  },
}

export const errorMessages = {
  en: {
    default_error: "An unknown error occurred.",
    camera_access_rear:
      "You need to grant camera access permission (tried rear camera)",
    camera_access_front:
      "You need to grant camera access permission (tried front camera)",
    permissions_not_allowed:
      "Camera permissions not allowed. You need to grant camera access permission.",
    no_camera_detected: "No camera detected on this device.",
    secure_context_required:
      "Secure context is required. Use HTTPS or localhost rather than HTTP.",
    camera_not_readable:
      "The camera stream is not readable. Is the camera already in use?",
    cameras_not_suitable: "Installed cameras are not suitable.",
    stream_api_not_supported: "Stream API is not supported in this browser.",
    insecure_context_error:
      "Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.",
  },
  de: {
    default_error: "Ein unbekannter Fehler ist aufgetreten.",
    camera_access_rear:
      "Sie müssen die Kamerazugriffsberechtigung erteilen (hintere Kamera versucht)",
    camera_access_front:
      "Sie müssen die Kamerazugriffsberechtigung erteilen (vordere Kamera versucht)",
    permissions_not_allowed:
      "Kamerazugriff nicht erlaubt. Die Berechtigung muss in den Browser-Einstellungen erteilt werden.",
    no_camera_detected: "Auf diesem Gerät wurde keine Kamera erkannt.",
    secure_context_required:
      "Sicherer Kontext erforderlich. Verwende HTTPS oder localhost anstelle von HTTP.",
    camera_not_readable:
      "Der Kamerastream ist nicht lesbar. Wird die Kamera bereits verwendet?",
    cameras_not_suitable: "Installierte Kameras sind nicht geeignet.",
    stream_api_not_supported:
      "Stream-API wird in diesem Browser nicht unterstützt.",
    insecure_context_error:
      "Kamerazugang ist nur in einem sicheren Kontext erlaubt. Verwende HTTPS oder localhost anstelle von HTTP.",
  },
  fr: {
    default_error: "Une erreur inconnue s'est produite.",
    camera_access_rear:
      "Vous devez accorder la permission d'accès à la caméra (caméra arrière essayée)",
    camera_access_front:
      "Vous devez accorder la permission d'accès à la caméra (caméra avant essayée)",
    permissions_not_allowed:
      "Permissions de caméra non autorisées. Vous devez accorder la permission d'accès à la caméra.",
    no_camera_detected: "Aucune caméra détectée sur cet appareil.",
    secure_context_required:
      "Un contexte sécurisé est requis. Utilisez HTTPS ou localhost au lieu de HTTP.",
    camera_not_readable:
      "Le flux de la caméra n'est pas lisible. La caméra est-elle déjà utilisée?",
    cameras_not_suitable: "Les caméras installées ne conviennent pas.",
    stream_api_not_supported:
      "L'API Stream n'est pas prise en charge dans ce navigateur.",
    insecure_context_error:
      "L'accès à la caméra n'est autorisé que dans un contexte sécurisé. Utilisez HTTPS ou localhost au lieu de HTTP.",
  },
  it: {
    default_error: "Si è verificato un errore sconosciuto.",
    camera_access_rear:
      "È necessario concedere il permesso di accesso alla fotocamera (provata fotocamera posteriore)",
    camera_access_front:
      "È necessario concedere il permesso di accesso alla fotocamera (provata fotocamera anteriore)",
    permissions_not_allowed:
      "Permessi della fotocamera non consentiti. È necessario concedere il permesso di accesso alla fotocamera.",
    no_camera_detected: "Nessuna fotocamera rilevata su questo dispositivo.",
    secure_context_required:
      "È richiesto un contesto sicuro. Utilizzare HTTPS o localhost invece di HTTP.",
    camera_not_readable:
      "Il flusso della fotocamera non è leggibile. La fotocamera è già in uso?",
    cameras_not_suitable: "Le fotocamere installate non sono adatte.",
    stream_api_not_supported:
      "L'API Stream non è supportata in questo browser.",
    insecure_context_error:
      "L'accesso alla fotocamera è consentito solo in un contesto sicuro. Utilizzare HTTPS o localhost invece di HTTP.",
  },
}
