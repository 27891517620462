<template>
  <div>
    <router-view v-slot="{ Component, route }">
      <Transition name="fade" mode="out-in">
        <div :key="route.fullPath">
          <component :is="Component"></component>
        </div>
      </Transition>
    </router-view>
  </div>
</template>
