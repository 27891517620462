<template>
  <Layout>
    <div class="flex gap-x-4 text-2xl p-4">
      <div v-for="locale of locales" class="uppercase">
        <router-link
          :to="{
            name: 'wg2_index',
            params: { locale, projectShortId: 'wg2' },
            query: { setLocale: locale },
          }"
          class="font-bold"
          :class="route.params.locale === locale ? '' : 'text-wg2-red'"
        >
          {{ locale }}
        </router-link>
      </div>
    </div>

    <div class="flex flex-col items-center justify-center gap-y-16 mt-8">
      <div class="text-5xl text-wg2-red font-bold text-center px-16">
        {{ t("index.welcome") }}
      </div>
      <div class="w-1/2 flex justify-center">
        <img src="@/assets/projects/wg2/images/scan_qr.png" />
      </div>
    </div>
  </Layout>
</template>

<script setup lang="ts">
import Layout from "@/layouts/projects/wg2/default.vue"

import { t } from "@/helpers/i18n"

const route = useRoute()
const locales = ["de", "fr", "it", "en"]
</script>

<style scoped></style>
