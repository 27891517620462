<template>
  <div class="font-mono p-4">
    <h1>Mock Installation</h1>
    <div class="mt-8 border p-4 border-black">
      installation_public_id=<span v-html="installationPublicId"></span>
    </div>
    <div class="mt-8 border p-4 border-black">
      upload
      <button class="bg-black px-2 text-white" @click="upload">upload</button>
    </div>
    <div class="mt-8 border p-4 border-black">
      present
      <button class="bg-black px-2 text-white" @click="present">present</button>
    </div>
    <div class="mt-8 border p-4 border-black">
      ready
      <button class="bg-black px-2 text-white" @click="ready">ready</button>
    </div>
    <div class="mt-8 border p-4 border-black">
      idle
      <button class="bg-black px-2 text-white" @click="idle">idle</button>
    </div>

    <div class="grid grid-cols-2 gap-x-16">
      <div class="w-full">
        <h2 class="mt-8">Log INCOMING</h2>
        <ul
          class="bg-black text-white p-4 pl-12 break-words w-full list-none"
          id="log-incoming"
        ></ul>
      </div>
      <div class="w-full">
        <h2 class="mt-8">Log OUTGOING</h2>
        <ul
          class="bg-black text-white p-4 pl-12 break-words w-full list-none"
          id="log-outgoing"
        ></ul>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { setupInstallationChannel } from "@/channels/installation"
import { InstallationChannel } from "@/channels/installation_channel"
import {
  StartSessionMessage,
  CaptureMessage,
  PresentMessage,
  ConfirmPresentMessage,
  ConfirmStartSessionMessage,
  ConfirmCaptureMessage,
  StopSessionMessage,
  UploadMessage,
  ConfirmUploadMessage,
  ReadyMessage,
  ConfirmReadyMessage,
  IdleMessage,
  ConfirmIdleMessage,
  CycleMessage,
  ConfirmCycleMessage,
  SelectMessage,
  ConfirmSelectMessage,
} from "@/channels/types"
import { logIncoming, logOutgoing } from "@/channels/mock_helpers"

const route = useRoute()
const installationPublicId = route.params.installation_public_id as string
const installationToken = route.params.installation_token as string

const channel = ref<InstallationChannel>()
const juaUserPublicId = ref()
const captureType = ref()

onMounted(async () => {
  channel.value = await setupInstallationChannel(
    installationPublicId,
    installationToken
  )

  // log INCOMING
  channel.value.on("start_session", async (message: StartSessionMessage) => {
    juaUserPublicId.value = message.jua_user_public_id
    logIncoming(message)
  })
  channel.value.on("select", async (message: SelectMessage) => {
    logIncoming(message)
  })
  channel.value.on("stop_session", async (message: StopSessionMessage) => {
    logIncoming(message)
  })
  channel.value.on("capture", async (message: CaptureMessage) => {
    captureType.value = message.type
    logIncoming(message)
  })
  channel.value.on("cycle", async (message: CycleMessage) => {
    logIncoming(message)
  })
  channel.value.on("confirm_upload", async (message: ConfirmUploadMessage) => {
    logIncoming(message)
  })
  channel.value.on("confirm_ready", async (message: ConfirmReadyMessage) => {
    logIncoming(message)
  })
  channel.value.on("confirm_idle", async (message: ConfirmIdleMessage) => {
    logIncoming(message)
  })
  channel.value.on(
    "confirm_present",
    async (message: ConfirmPresentMessage) => {
      logIncoming(message)
    }
  )

  // log OUTGOING
  channel.value.on(
    "confirm_start_session",
    async (message: ConfirmStartSessionMessage) => {
      logOutgoing("confirm_start_session", message)
    }
  )
  channel.value.on("confirm_select", async (message: ConfirmSelectMessage) => {
    logOutgoing("confirm_select", message)
  })
  channel.value.on(
    "confirm_capture",
    async (message: ConfirmCaptureMessage) => {
      logOutgoing("confirm_capture", message)
    }
  )
  channel.value.on("confirm_cycle", async (message: ConfirmCycleMessage) => {
    logOutgoing("confirm_cycle", message)
  })
  channel.value.on("upload", async (message: UploadMessage) => {
    logOutgoing("upload", message)
  })
  channel.value.on("ready", async (message: ReadyMessage) => {
    logOutgoing("ready", message)
  })
  channel.value.on("idle", async (message: IdleMessage) => {
    logOutgoing("idle", message)
  })
  channel.value.on("present", async (message: PresentMessage) => {
    logOutgoing("present", message)
  })

  window.setInterval(() => {
    channel.value?.heartbeat()
  }, 60000)
})

const upload = async () => {
  if (captureType.value) {
    await channel.value?.upload({ type: captureType.value })
  }
}
const ready = async () => {
  await channel.value?.ready({})
}
const idle = async () => {
  await channel.value?.idle({})
}
const present = async () => {
  const data = {
    type: captureType.value,
    success: true,
    url: "https://picsum.photos/id/237/200/300",
    jua_user_public_id: juaUserPublicId.value,
  }
  if (data) {
    await channel.value?.present(data)
  }
}
</script>

<style scoped></style>
