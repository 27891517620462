<template>
  <Layout :hide-actions="hideActions">
    <div class="overlay text-right pr-5" @click="close">
      <div v-if="slug === 'draw'" class="text-7xl text-white">&times;</div>
    </div>
    <iframe v-if="frameSrc" :src="frameSrc" class="iframe" />
  </Layout>
</template>

<script setup lang="ts">
import Layout from "@/layouts/projects/wg2/default.vue"

const route = useRoute()
const router = useRouter()

const hideActions = ref(true)
const frameSrc = ref()
const slug = ref()

onMounted(async () => {
  const frames = {
    xmas: "https://wow-guide.ch/iframes/extras/xmas/xmas.html",
    piano:
      "https://wow-guide.ch/iframes/extras/wow_music/wow_music.html?inst=keys",
    drums:
      "https://wow-guide.ch/iframes/extras/wow_music/wow_music.html?inst=drums",
    draw: "https://wow-guide.ch/iframes/extras/wow_draw/index.html",
  }
  slug.value = route.params?.slug as
    | "xmas"
    | "piano"
    | "drums"
    | "draw"
    | undefined
  if (slug && Object.keys(frames).includes(slug.value)) {
    frameSrc.value = frames[slug.value]
  } else {
    hideActions.value = false
  }
})

const close = () => {
  console.log("close")
  frameSrc.value = ""
  router.push({
    name: "wg2_index",
    params: { locale: "de", projectShortId: "wg2" },
  })
}
</script>

<style scoped lang="postcss">
.overlay {
  @apply absolute right-0 top-0; /* bg-opacity-25 bg-green-500 */

  width: 12%;
  min-width: 70px;
  height: 30%;
  min-height: 120px;
}
.iframe {
  @apply w-full h-screen;
}

@supports (-webkit-touch-callout: none) {
  .h-screen {
    height: -webkit-fill-available !important;
  }
}
</style>
