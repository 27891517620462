<template>
  <div class="font-mono p-4">
    <h1>Mock Client</h1>
    <div class="mt-8 border p-4 border-black">
      installation_public_id=<span v-html="installationPublicId"></span>
    </div>
    <div class="mt-8 border p-4 border-black">
      jua_user_public_id=<span v-html="juaUserPublicId"></span>
    </div>
    <div class="mt-8 border p-4 border-black">
      session_hash=<span v-html="sessionHash"></span>
    </div>
    <div class="mt-8 border p-4 border-black">
      start_session
      <button
        class="bg-black px-2 text-white"
        id="start_session"
        @click="startSession"
      >
        start_session
      </button>
      startSessionKind=
      <select v-model="startSessionKind" class="border border-black">
        <option v-for="(v, k) of startSessionKinds" :value="k">{{ v }}</option>
      </select>
    </div>
    <div class="mt-8 border p-4 border-black">
      extend_session
      <button class="bg-black px-2 text-white" @click="extendSession">
        extend_session
      </button>
      <span class="pl-8" id="time_remaining"></span>
    </div>
    <div class="mt-8 border p-4 border-black">
      select
      <button class="bg-black px-2 text-white" id="select" @click="select">
        select
      </button>
      selectKind=
      <select v-model="selectKind" class="border border-black">
        <option v-for="(v, k) of selectKinds" :value="k">{{ v }}</option>
      </select>
    </div>
    <div class="mt-8 border p-4 border-black">
      capture
      <button class="bg-black px-2 text-white" @click="capture('photo')">
        photo
      </button>
      <button class="bg-black px-2 text-white ml-2" @click="capture('gif')">
        gif
      </button>
    </div>
    <div class="mt-8 border p-4 border-black">
      cycle
      <button class="bg-black px-2 text-white" @click="cycle">cycle</button>
    </div>
    <div class="grid grid-cols-2 gap-x-16">
      <div class="w-full">
        <h2 class="mt-8">Log INCOMING</h2>
        <ul
          class="bg-black text-white p-4 pl-12 break-words w-full list-none"
          id="log-incoming"
        ></ul>
      </div>
      <div class="w-full">
        <h2 class="mt-8">Log OUTGOING</h2>
        <ul
          class="bg-black text-white p-4 pl-12 break-words w-full list-none"
          id="log-outgoing"
        ></ul>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { setupClientChannel } from "@/channels/client"
import { ClientChannel } from "@/channels/client_channel"
import {
  CaptureMessage,
  ConfirmCaptureMessage,
  CycleMessage,
  ConfirmCycleMessage,
  ConfirmUploadMessage,
  ConfirmReadyMessage,
  ConfirmIdleMessage,
  ConfirmPresentMessage,
  ConfirmStartSessionMessage,
  ExtendSessionMessage,
  PresentMessage,
  StartSessionMessage,
  UploadMessage,
  ReadyMessage,
  IdleMessage,
  StopSessionMessage,
  SelectMessage,
  ConfirmSelectMessage,
} from "@/channels/types"
import { logIncoming, logOutgoing } from "@/channels/mock_helpers"

const route = useRoute()
const installationPublicId = route.params.installation_public_id as string
const juaUserPublicId = route.params.jua_user_public_id as string
const juaUserToken = route.params.jua_user_token as string
const sessionHash = route.params.session_hash as string

const startSessionKind = ref("")
const startSessionKinds = {
  "": "None",
  foo: "Foo",
  bar: "Bar",
}

const selectKind = ref("color_12")
const selectKinds = {
  button_show_1: "button_show_1",
  button_show_2: "button_show_2",
  slider_color_12: "slider_color_12",
  slider_color_34: "slider_color_34",
  slider_brightness_56: "slider_brightness_56",
  slider_brightness_78: "slider_brightness_78",
  toggle_show: "toggle_show",
}

const channel = ref<ClientChannel>()

onMounted(async () => {
  channel.value = await setupClientChannel(
    installationPublicId,
    juaUserToken,
    sessionHash
  )

  // log INCOMING
  channel.value.on(
    "confirm_start_session",
    async (message: ConfirmStartSessionMessage) => {
      logIncoming(message)
    }
  )
  channel.value.on("confirm_select", async (message: ConfirmSelectMessage) => {
    logIncoming(message)
  })
  channel.value.on(
    "confirm_capture",
    async (message: ConfirmCaptureMessage) => {
      logIncoming(message)
    }
  )
  channel.value.on("confirm_cycle", async (message: ConfirmCycleMessage) => {
    logIncoming(message)
  })
  channel.value.on("upload", async (message: UploadMessage) => {
    logIncoming(message)
  })
  channel.value.on("ready", async (message: ReadyMessage) => {
    logIncoming(message)
  })
  channel.value.on("idle", async (message: IdleMessage) => {
    logIncoming(message)
  })
  channel.value.on("present", async (message: PresentMessage) => {
    logIncoming(message)
  })
  channel.value.on("stop_session", async (message: StopSessionMessage) => {
    logIncoming(message)
    alert("stop_session!")
  })

  // log OUTGOING
  channel.value.on("start_session", async (message: StartSessionMessage) => {
    logOutgoing("start_session", message)
  })
  channel.value.on("select", async (message: SelectMessage) => {
    logOutgoing("select", message)
  })
  channel.value.on("extend_session", async (message: ExtendSessionMessage) => {
    logOutgoing("extend_session", message)
  })
  channel.value.on("capture", async (message: CaptureMessage) => {
    logOutgoing("capture", message)
  })
  channel.value.on("cycle", async (message: CycleMessage) => {
    logOutgoing("cycle", message)
  })
  channel.value.on("confirm_upload", async (message: ConfirmUploadMessage) => {
    logOutgoing("confirm_upload", message)
  })
  channel.value.on("confirm_ready", async (message: ConfirmReadyMessage) => {
    logOutgoing("confirm_ready", message)
  })
  channel.value.on("confirm_idle", async (message: ConfirmIdleMessage) => {
    logOutgoing("confirm_idle", message)
  })
  channel.value.on(
    "confirm_present",
    async (message: ConfirmPresentMessage) => {
      logOutgoing("confirm_present", message)
    }
  )
})

const startSession = () => {
  channel.value?.start_session({
    jua_user_public_id: juaUserPublicId,
    kind: startSessionKind.value,
  })
}

const select = () => {
  channel.value?.select({
    kind: selectKind.value,
  })
}

const extendSession = () => {
  channel.value?.extend_session({
    action: "extend_session",
  })
}

const capture = (type: string) => {
  channel.value?.capture({
    type,
  })
}

const cycle = () => {
  channel.value?.cycle({})
}
</script>

<style scoped></style>
