import { Message } from "./types"

const log = (type: string, message: Message, action: any = undefined) => {
  const node = document.createElement("li")
  node.classList.add("message")
  node.classList.add("-indent-8")
  if (action !== undefined && type === "outgoing") {
    message = { command: "message", data: { ...message, action } }
  }
  const textnode = document.createTextNode(JSON.stringify(message))
  node.appendChild(textnode)
  document.querySelector("#log-" + type)?.appendChild(node)
}

export const logOutgoing = (action: string, message: Message) =>
  log("outgoing", message, action)
export const logIncoming = (message: Message) => log("incoming", message)
