import { ProjectConfig, StoryVersion } from "@/api/types"

export default async (
  to: any,
  from: any,
  next: any,
  projectConfig: ProjectConfig,
  rawStoryVersion: StoryVersion
) => {
  const store = useWg2Store()

  const storyVersionPublicId = rawStoryVersion.public_id

  // if there is no journey and user in the store, we create them
  if (
    store?.getJourney?.public_id === undefined ||
    store?.getUser?.public_id === undefined
  ) {
    // console.log("Journey and user do not exist...")
    // console.log("initializeAnonymousJuaUserWithJourney", storyVersionPublicId)
    await store.initializeAnonymousJuaUserWithJourney(storyVersionPublicId)
  } else {
    // console.log("Existing journey and user")
    // console.log(store.getJourney)
    // console.log(store.getUser)
  }

  let beforeHookNext = undefined

  // If the onboarding has not been completed, let's redirect to the onboarding
  if (
    store?.getOnboardingCompleted !== true &&
    to.name !== "wg2_onboarding" &&
    to.name !== "wg2_shared_journey"
  ) {
    //console.log(router)
    beforeHookNext = {
      name: "wg2_onboarding",
      params: {
        projectShortId: "wg2",
        locale: from.locale || projectConfig.config.defaultLocale || "en",
      },
    }
  }
  return beforeHookNext
}
