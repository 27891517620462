<template>
  <div class="pulsedot"></div>
</template>

<script setup lang="ts">
// import { Vue3Lottie } from "vue3-lottie"
// import RedSpinnerJSON from "@/assets/projects/wg2/animations/red-spinner.json"

import { ref, onMounted } from "vue"

import { get, post } from "@/api/main"

const route = useRoute()
const router = useRouter()
const store = useStore()

const locale = ref(route.params.locale as string)
const projectShortId = ref(route.params.projectShortId as string)
const storyVersionPublicId = ref(route.params.storyVersionPublicId as string)
const sceneId = ref(route.params.sceneId as string)

const getStoryVersion = async () => {
  const storyVersion = await get.storyVersion(storyVersionPublicId.value)
  if (storyVersion.data.story.scenes?.length) {
    return storyVersion
  }
}

onMounted(async () => {
  document.body.style.overflow = "scroll"

  const storyVersion = await getStoryVersion()
  if (storyVersion) {
    await store.setStoryVersion(storyVersion)

    if (locale.value && projectShortId.value && sceneId.value) {
      // const journey = await post.journey(storyVersionPublicId.value)
      // const user = await post.user(journey.id, "foo_bar_a1@gmail.com")
      // console.log(user)
      router.push({
        name: "scene",
        params: {
          locale: locale.value,
          projectShortId: projectShortId.value,
          storyVersionPublicId: storyVersionPublicId.value,
          sceneId: sceneId.value,
        },
      })
    } else {
      throw new Error("Missing sceneId")
    }
  } else {
    throw new Error("Missing storyVersion")
  }
})
</script>
