import { Channel, ChannelEvents } from "@anycable/web"
import {
  HeartbeatMessage,
  StartSessionMessage,
  SelectMessage,
  StopSessionMessage,
  CaptureMessage,
  ConfirmCaptureMessage,
  ConfirmPresentMessage,
  ConfirmStartSessionMessage,
  ConfirmSelectMessage,
  ReadyMessage,
  ConfirmReadyMessage,
  IdleMessage,
  ConfirmIdleMessage,
  PresentMessage,
  ActionMessage,
  UploadMessage,
  ConfirmUploadMessage,
  CycleMessage,
  ConfirmCycleMessage,
} from "./types"

type Params = {
  installation_public_id: string
}

interface Events extends ChannelEvents<ActionMessage> {
  message: (message: any) => void
  connect: (message: any) => void
  disconnect: (message: any) => void
  close: (message: any) => void
  // utility
  heartbeat: (message: HeartbeatMessage) => void
  // incoming
  stop_session: (message: StopSessionMessage) => void
  start_session: (message: StartSessionMessage) => void
  select: (message: SelectMessage) => void
  capture: (message: CaptureMessage) => void
  cycle: (message: CycleMessage) => void
  confirm_upload: (message: ConfirmUploadMessage) => void
  confirm_ready: (message: ConfirmReadyMessage) => void
  confirm_idle: (message: ConfirmIdleMessage) => void
  confirm_present: (message: ConfirmPresentMessage) => void
  // outgoing
  confirm_start_session: (message: ConfirmStartSessionMessage) => void
  confirm_select: (message: ConfirmSelectMessage) => void
  confirm_capture: (message: ConfirmCaptureMessage) => void
  confirm_cycle: (message: ConfirmCycleMessage) => void
  upload: (message: UploadMessage) => void
  ready: (message: ReadyMessage) => void
  idle: (message: IdleMessage) => void
  present: (message: PresentMessage) => void
}

export class InstallationChannel extends Channel<
  Params,
  ActionMessage,
  Events
> {
  static identifier = "InstallationChannel"

  async heartbeat() {
    this.emit("heartbeat", {})
    return this.perform("heartbeat")
  }

  async upload(message: UploadMessage) {
    this.emit("upload", message)
    return this.perform("upload", message)
  }

  async ready(message: ReadyMessage) {
    this.emit("ready", message)
    return this.perform("ready", message)
  }

  async idle(message: IdleMessage) {
    this.emit("idle", message)
    return this.perform("idle", message)
  }

  async present(message: PresentMessage) {
    this.emit("present", message)
    return this.perform("present", message)
  }

  receive(message: ActionMessage) {
    if (message.action === "start_session") {
      this.emit("start_session", message as StartSessionMessage)
      this.perform("confirm_start_session", message)
      this.emit("confirm_start_session", message as ConfirmStartSessionMessage)
    } else if (message.action === "select") {
      this.emit("select", message as SelectMessage)
      this.perform("confirm_select", message)
      this.emit("confirm_select", message as ConfirmSelectMessage)
    } else if (message.action === "capture") {
      this.emit("capture", message as CaptureMessage)
      this.perform("confirm_capture", message)
      this.emit("confirm_capture", message as ConfirmCaptureMessage)
    } else if (message.action === "cycle") {
      this.emit("cycle", message as CycleMessage)
      this.perform("confirm_cycle", message)
      this.emit("confirm_cycle", message as ConfirmCycleMessage)
    } else if (message.action === "confirm_upload") {
      this.emit("confirm_upload", message as ConfirmUploadMessage)
    } else if (message.action === "confirm_ready") {
      this.emit("confirm_ready", message as ConfirmReadyMessage)
    } else if (message.action === "confirm_idle") {
      this.emit("confirm_idle", message as ConfirmIdleMessage)
    } else if (message.action === "confirm_present") {
      this.emit("confirm_present", message as ConfirmPresentMessage)
    } else if (message.action === "stop_session") {
      this.emit("stop_session", message as StopSessionMessage)
    }

    super.receive(message)
  }
}
