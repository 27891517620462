<template>
  <div class="flex flex-col h-screen">
    <div class="flex justify-between items-center p-8">
      <h1 class="flex items-center h-8 font-bold text-3xl text-wg2-red">
        FLICKER
      </h1>
      <button
        class="flex items-center h-10 font-bold text-5xl pb-2 text-wg2-red"
        @click="router.back()"
      >
        &times;
      </button>
    </div>
    <div class="bg-wg2-red flex-grow w-full">
      <video controls autoplay class="w-full h-full object-cover">
        <source
          src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
          type="video/mp4"
        />
      </video>
    </div>
  </div>
</template>

<script setup lang="ts">
const router = useRouter()
onMounted(() => {
  document.body.style.overflow = "scroll"

  window.scrollTo({ top: 0, behavior: "smooth" })
})
</script>

<style scoped></style>
