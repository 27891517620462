import { createCable } from "@anycable/web"
import { cableUrlFor } from "./cable_url"

import { InstallationChannel } from "./installation_channel"

export const setupInstallationChannel = async (
  installationPublicId: string,
  installationToken: string
): Promise<InstallationChannel> => {
  const channel = new InstallationChannel({
    installation_public_id: installationPublicId,
  })
  const cable = createCable(cableUrlFor(installationToken))
  cable.subscribe(channel)
  await channel.ensureSubscribed()
  channel.on("connect", () => console.log("Connected"))
  channel.on("disconnect", () => console.log("No connection"))
  channel.on("close", () => console.log("Disconnected"))

  return channel
}
