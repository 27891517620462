<template>
  <div class="pulsedot"></div>
</template>

<script setup lang="ts">
// import { Vue3Lottie } from "vue3-lottie"
// import RedSpinnerJSON from "@/assets/projects/wg2/animations/red-spinner.json"

import { ref, onMounted } from "vue"

import { get } from "@/api/main"
import { StoryVersion, Installation } from "@/api/types"

const route = useRoute()
const router = useRouter()
const store = useStore()

const sessionHash = ref(route.params.sessionHash as string)
const locale = ref(route.params.locale as string)

const getInstallation = async () => {
  const installationPublicId = sessionHash.value.slice(0, 8)
  const installation = await get.installation(installationPublicId)
  if (installation.published_story_version.data.story.scenes?.length) {
    return installation
  }
}

const getStoryVersionFromInstallation = async (installation: Installation) => {
  if (installation.published_story_version.data.story.scenes?.length) {
    return installation.published_story_version
  }
}

const getDefaultLocale = (storyVersion: StoryVersion) => {
  if (storyVersion.data.story.default_locale) {
    return storyVersion.data.story.default_locale as string
  }
  return "en"
}

const getProjectShortId = (storyVersion: StoryVersion) => {
  if (storyVersion.data.project.short_id) {
    return storyVersion.data.project.short_id as string
  }
  return "default"
}

onMounted(async () => {
  document.body.style.overflow = "scroll"

  const installation = await getInstallation()
  if (installation) {
    const storyVersion = await getStoryVersionFromInstallation(installation)
    if (storyVersion) {
      await store.setStoryVersion(storyVersion)
      const sceneId = installation?.scene_id
      const defaultLocale = getDefaultLocale(storyVersion)
      const projectShortId = getProjectShortId(storyVersion)
      if (sceneId) {
        router.push({
          name: "scene",
          params: {
            locale: locale.value || defaultLocale,
            projectShortId,
            storyVersionPublicId: storyVersion.public_id,
            sceneId,
          },
          query: {
            h: route.params.sessionHash,
          },
        })
      } else {
        throw new Error("Missing sceneId")
      }
    } else {
      throw new Error("Missing storyVersion")
    }
  } else {
    throw new Error("Missing scanpoint")
  }
})
</script>
