<template>
  <div class="inset-0 fixed bg-white" style="z-index: 100">
    <Transition name="fade">
      <div v-if="loading" class="bg-white inset-0 fixed" style="z-index: 400">
        <div class="pulsedot"></div>
      </div>
    </Transition>

    <Transition name="fade">
      <div
        v-if="redirecting"
        class="bg-white inset-0 fixed"
        style="z-index: 400"
      ></div>
    </Transition>

    <div class="inset-0 fixed bg-white" style="z-index: 200">
      <div v-if="!loading" class="absolute" style="z-index: 300">
        <div
          v-if="errorMessage"
          class="text-wg2-red w-full flex flex-col p-8 text-center items-center text-2xl bg-white gap-4"
        >
          <div class="text-wg2-red">
            <div class="material-symbols-rounded material-symbols-rounded-xl">
              sentiment_dissatisfied
            </div>
          </div>
          <div class="font-bold">{{ errorName }}</div>
          <div>{{ errorMessage }}</div>

          <div class="flex flex-col gap-4 mt-2">
            <div>
              <button class="btn mx-auto flex gap-2" @click="switchCamera">
                <div
                  class="material-symbols-rounded material-symbols-rounded-sm"
                >
                  photo_camera
                </div>

                {{ getErrorRecoveryMessage("try_to_switch_camera") }}
              </button>
            </div>
            <div>
              <button @click="reload" class="btn mx-auto flex gap-2">
                <div
                  class="material-symbols-rounded material-symbols-rounded-sm"
                >
                  refresh
                </div>

                {{ getErrorRecoveryMessage("try_to_reload") }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="h-screen absolute w-full" v-if="!errorMessage">
        <div class="svg-container" :class="{ decoded }">
          <svg class="overlay">
            <circle
              cx="50%"
              cy="50%"
              r="48%"
              stroke="currentColor"
              fill="transparent"
              stroke-linecap="round"
              stroke-dasharray="55% 21%"
              stroke-dashoffset="28%"
            />
          </svg>
        </div>
      </div>

      <div class="absolute h-screen w-screen">
        <QrcodeStream
          @detect="onDetect"
          @error="onError"
          :constraints="constraints"
          v-if="!destroyed"
        />
      </div>

      <Transition name="fade">
        <div
          v-if="!loading && !decoded"
          class="w-full absolute bottom-8 flex flex-col items-center justify-center z-20 select-none"
        >
          <div
            class="mx-auto w-48 text-2xl text-center text-wg2-red pb-10 font-bold"
            id="label"
            v-show="!errorMessage"
          >
            {{ t("qr_code_scanner.find_qr_codes") }}
          </div>

          <div class="flex gap-x-4 justify-center items-end">
            <div class="h-14 w-14"></div>

            <button
              class="mx-5 rounded-full bg-wg2-red h-14 w-14 text-white flex justify-center items-center transition-all ease-in-out duration-500 origin-bottom scale-125 active:scale-150"
              @click="close"
            >
              <div
                class="w-12 h-12 border-2 border-white bg-wg2-red flex justify-center rounded-full items-center"
              >
                <span class="material-symbols-rounded">close</span>
              </div>
            </button>

            <!-- <button
              @click="switchCamera"
              class="rounded-full bg-wg2-red h-14 w-14 text-white flex justify-center items-center transition-all active:scale-125"
            >
              <div
                class="w-12 h-12 border-2 border-white bg-wg2-red flex justify-center rounded-full items-center"
              >
                <span class="material-symbols-rounded">cameraswitch</span>
              </div>
            </button> -->
            <div class="h-14 w-14"></div>
          </div>
        </div>
      </Transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import { QrcodeStream } from "vue-qrcode-reader"

import { onDecode } from "@/helpers/qr"
import { t } from "@/helpers/i18n"
import { errorMessages, errorRecoveryMessages } from "@/errors/qr_code_reader"
import { appsignal } from "@/appsignal"

const emit = defineEmits(["hide"])

const route = useRoute()
const router = useRouter()
const locale = route?.params?.locale || "de"

const redirecting = ref(false)
const loading = ref(true)
const destroyed = ref(false)
const decoded = ref(false)
const result = ref("")
const errorName = ref("")
const errorMessage = ref("")
const facingMode = ref("environment")

const constraints = computed(() =>
  facingMode?.value
    ? { facingMode: facingMode.value }
    : { facingMode: "environment" }
)

const reload = async () => {
  destroyed.value = true
  destroyed.value = false
  loading.value = true
  window.location.reload()
}

const close = () => {
  document.body.style.overflow = "scroll"
  loading.value = true
  decoded.value = false
  emit("hide")
}

const switchCamera = () => {
  loading.value = true
  destroyed.value = true

  window.setTimeout(() => {
    loading.value = false
    destroyed.value = false
    errorName.value = ""
    errorMessage.value = ""
    switch (facingMode.value) {
      case "environment":
        facingMode.value = "user"
        break
      case "user":
        facingMode.value = "environment"
        break
    }
  }, 1500)
}

onMounted(async () => {
  window.scrollTo({ top: 0, behavior: "smooth" })
  document.body.style.overflow = "hidden"
  window.setTimeout(() => {
    loading.value = false
  }, 1500)
})

onBeforeUnmount(() => {
  window.scrollTo({ top: 0, behavior: "smooth" })
  document.body.style.overflow = "scroll"
})

// const onReady = async (capabilities: MediaTrackCapabilities) => {
//   loading.value = false
//   if (!initialized.value) {
//     initialized.value = true
//     window.setTimeout(() => {
//       paused.value = true
//     }, 2000)

//     window.setTimeout(() => {
//       paused.value = false
//       console.log("GO!")
//     }, 3000)
//   }
// }

const onDetect = (detectedCodes: any[]) => {
  console.log({ detectedCodes })
  decoded.value = true

  const [firstCode] = detectedCodes
  result.value = firstCode?.rawValue

  window.setTimeout(() => {
    redirecting.value = true
  }, 500)

  window.setTimeout(() => {
    decoded.value = false
    onDecode(result.value, router, route.params.locale as string)
  }, 1000)
}

const getErrorMessage = (errorName: string) => {
  const messages = errorMessages?.[locale] || errorMessages["de"] // default to German if locale not found
  const defErr = messages?.default_error_message || "Unknown error"
  switch (errorName) {
    case "NotAllowedError":
      return messages?.permissions_not_allowed || defErr
    case "NotFoundError":
      return messages?.no_camera_detected || defErr
    case "NotSupportedError":
      return messages?.secure_context_required || defErr
    case "NotReadableError":
      return messages?.camera_not_readable || defErr
    case "OverconstrainedError":
      return messages?.cameras_not_suitable || defErr
    case "StreamApiNotSupportedError":
      return messages?.stream_api_not_supported || defErr
    case "InsecureContextError":
      return messages?.insecure_context_error || defErr
    default:
      return defErr // ensure this key exists for unknown errors
  }
}

const getErrorRecoveryMessage = (k: string) => {
  const messages =
    errorRecoveryMessages?.[locale] || errorRecoveryMessages["de"] // default to German if locale not found
  return messages?.[k]
}

const onError = (error: Error) => {
  loading.value = false

  console.error(error)

  const triedFrontCamera = facingMode.value === "user"
  const triedRearCamera = facingMode.value === "environment"
  const cameraMissingError = error.name === "OverconstrainedError"

  errorName.value = error?.name || "Error"

  if (triedRearCamera && cameraMissingError) {
    errorMessage.value = getErrorMessage("camera_access_rear")
  } else if (triedFrontCamera && cameraMissingError) {
    errorMessage.value = getErrorMessage("camera_access_front")
  } else {
    errorMessage.value = getErrorMessage(error.name)
  }

  const wg2Store = useWg2Store()
  appsignal.sendError(error, (span) => {
    span.setTags({
      userPublicId: wg2Store?.getUser?.public_id || "N/A",
      journeyPublicId: wg2Store?.getJourney?.public_id || "N/A",
    })
  })
}
</script>

<style scoped lang="postcss">
.btn {
  @apply bg-wg2-red text-white px-4 py-1 text-lg rounded-full font-bold;
}

/* -------------------- */

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* -------------------- */

.svg-container {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20vh;
  background: radial-gradient(
    circle at 50vw 40vh,
    transparent 40vw,
    rgba(255, 255, 255, 0.3) 40.01vw
  );
}

.svg-container.decoded {
  background: radial-gradient(
    circle at 50vw 40vh,
    transparent 40vw,
    rgba(0, 255, 0, 0.5) 40.01vw
  );
  animation: ping-custom 1s forwards;
}

.svg-container.decoded svg.overlay {
  color: rgba(0, 255, 0, 1);
  stroke-width: 8px;
  animation: ping-custom 10s forwards;
}

svg.overlay {
  stroke-width: 4px;
  color: white;
  width: 84vw;
  height: 84vw;
  transition: stroke-width 1s ease;
}

@keyframes ping-custom {
  75% {
    transform: scale(1.2);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
</style>
