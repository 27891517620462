<template>
  <transition name="fade">
    <div
      v-if="isUploading"
      class="absolute z-50 inset-0 bg-wg2-red flex items-center justify-center"
    >
      <div class="w-40 h-40 flex items-center justify-center overflow-hidden">
        <Vue3Lottie :animationData="WhiteSpinnerJSON" />
      </div>
    </div>
  </transition>

  <div
    v-if="infinityRoomInstallationControlsViewShown"
    ref="infinityRoomInstallationControls"
    class="inset-0 absolute p-4 bg-transparent overflow-hidden pb-20 z-40"
  >
    <Transition name="fade" mode="out-in">
      <div
        class="flex flex-col justify-between h-full"
        v-if="showShowSelection"
      >
        <Transition name="fade-scale" mode="out-in">
          <div
            v-if="showFadeScale > 0"
            key="a"
            class="instructions text-white text-xl font-bold py-6 pl-6 pr-32 flex-shrink h-32"
          >
            {{ t("wow_infinity_room.choose_before_entering") }}
          </div>
          <div v-else class="h-32"></div>
        </Transition>

        <Transition name="fade-scale" mode="out-in">
          <button
            v-if="showFadeScale > 1"
            key="b"
            @click="setShow(1)"
            class="bubble"
            :class="show === 1 ? 'active' : ''"
          >
            <img src="/wg2/wow_inf2_1.png" />
            <div class="label -left-32">
              Interstellar
              <br />
              Hans Zimmer
            </div>
          </button>
          <div v-else class="bubble opacity-0"></div>
        </Transition>

        <Transition name="fade-scale" mode="out-in">
          <button
            v-if="showFadeScale > 2"
            @click="setShow(2)"
            class="bubble"
            :class="show === 2 ? 'active' : ''"
          >
            <img src="/wg2/wow_inf2_2.png" />
            <div class="label -right-32">
              Diamonds Veins
              <br />
              French79
            </div>
          </button>
          <div v-else class="bubble opacity-0"></div>
        </Transition>

        <Transition name="fade-scale" mode="out-in">
          <button
            v-if="showFadeScale > 3"
            @click="setShow(3)"
            class="bubble"
            :class="show === 3 ? 'active' : ''"
          >
            <img src="/wg2/wow_inf2_3.png" />
            <div class="label -left-32">
              A New Error
              <br />
              Moderat
            </div>
          </button>
          <div v-else class="bubble opacity-0"></div>
        </Transition>
      </div>
      <div v-else>
        <div class="p-6">
          <div class="flex flex-col gap-8 mb-8">
            <div class="instructions text-white text-xl font-bold">
              {{ t("wow_infinity_room.pause_to_take_picture") }}
            </div>
            <div>
              <button
                id="toggleShow"
                class="rounded-full text-lg text-wg2-red bg-white border-2 border-transparent font-bold px-5 py-1"
                @click="toggleShow"
                :class="{ 'inactive-button': !toggleIsActive }"
              >
                {{ toggleLabel }}
              </button>
            </div>
          </div>
          <div class="flex flex-col gap-8" v-if="!toggleIsActive">
            <div>
              <div class="text-lg text-white font-bold mb-2">
                {{ t("wow_infinity_room.set_color") }}:
              </div>
              <input
                id="colorRange"
                type="range"
                :min="0"
                :max="maxColor"
                @input="updateColor"
                :value="color"
              />
            </div>
            <div>
              <div class="text-lg text-white font-bold mb-2">
                {{ t("wow_infinity_room.set_brightness") }}:
              </div>
              <input
                id="brightnessRange"
                type="range"
                :min="0"
                :max="maxBrightness"
                @input="updateBrightness"
                :value="brightness"
              />
            </div>

            <div>
              <button>
                <label for="cameraFileInput" class="custom-file-upload">
                  <span
                    class="px-4 bg-white flex gap-x-2 justify-center rounded-full items-center rounded-full text-lg bg-white text-wg2-red font-bold px-5 py-1"
                  >
                    <span
                      class="material-symbols-rounded material-symbols-rounded-sm"
                      >add_a_photo</span
                    >
                    {{ t("wow_infinity_room.capture_photo") }}
                  </span>
                </label>

                <input
                  id="cameraFileInput"
                  type="file"
                  accept="image/*"
                  capture="environment"
                  class="hidden"
                  @change="handleSnap"
                />
              </button>
              <div class="font-bold text-white mt-4">
                <span v-if="numberOfUploadedSnaps === 0">
                  {{
                    t("wow_infinity_room.captured_photos_located_in_journey")
                  }}
                </span>
                <span v-else>
                  {{ capturedPhotosString }}
                  {{
                    t("wow_infinity_room.captured_photos_located_in_journey")
                  }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import WhiteSpinnerJSON from "@/assets/projects/wg2/animations/white-spinner.json"
import { Vue3Lottie } from "vue3-lottie"

import { post } from "@/api/main"
import {
  UploadError,
  SelectionError,
  NoJourneyError,
} from "@/errors/user_capture"

import { ClientChannel } from "@/channels/client_channel"
import { setupClientChannel, cleanClientChannel } from "@/channels/client"
import { StartSessionMessage } from "@/channels/types"
import { gsap } from "gsap"

import { ref } from "vue"

import { t } from "@/helpers/i18n"

const route = useRoute()
const store = useWg2Store()

const infinityRoomInstallationControls = ref()

const showShowSelection = ref(true)

const toggleIsActive = ref(true)

const show = ref(0)
const color = ref(50)
const maxColor = 100
const brightness = ref(50)
const maxBrightness = 100

const showDurations = {
  1: 124,
  2: 175,
  3: 160,
}

const props = defineProps<{
  infinityRoomInstallationControlsViewShown: boolean
}>()

const emit = defineEmits(["hide"])

const channel = ref<ClientChannel>()

const setShow = async (i: number) => {
  show.value = i
  await selectShow()
  await extendSession()
}

const toggleLabel = computed(() =>
  toggleIsActive.value
    ? t("wow_infinity_room.animation_toggle_pause")
    : t("wow_infinity_room.animation_toggle_continue")
)

const toggleShow = async () => {
  toggleIsActive.value = !toggleIsActive.value
  try {
    await channel.value?.select({ kind: `toggle_show` })
  } catch (error) {
    console.error("An error occurred:", error)
  }
}

const selectShow = async () => {
  try {
    await channel.value?.select({ kind: `button_show_${show.value}` })
  } catch (error) {
    console.error("An error occurred:", error)
  }
}

let lastColorUpdate = Date.now()
let lastBrightnessUpdate = Date.now()
let colorTimer, brightnessTimer

const callColorFunction = async () => {
  try {
    await channel.value?.select({ kind: `slider_color_${color.value}` })
  } catch (error) {
    console.error("An error occurred:", error)
  }
}

const callBrightnessFunction = async () => {
  try {
    await channel.value?.select({
      kind: `slider_brightness_${brightness.value}`,
    })
  } catch (error) {
    console.error("An error occurred:", error)
  }
}

const debounceThrottle = 250

const selectColor = (value: number) => {
  clearTimeout(colorTimer)
  const now = Date.now()
  const diff = now - lastColorUpdate

  if (diff >= debounceThrottle) {
    callColorFunction(value)
    lastColorUpdate = now
  } else {
    colorTimer = setTimeout(() => selectColor(value), debounceThrottle - diff)
  }
}

const selectBrightness = (value: number) => {
  clearTimeout(brightnessTimer)
  const now = Date.now()
  const diff = now - lastBrightnessUpdate

  if (diff >= debounceThrottle) {
    callBrightnessFunction(value)
    lastBrightnessUpdate = now
  } else {
    brightnessTimer = setTimeout(
      () => selectBrightness(value),
      debounceThrottle - diff
    )
  }
}

const updateColor = (event: Event) => {
  color.value = Number((event.target as HTMLInputElement).value)
}

const updateBrightness = (event: Event) => {
  brightness.value = Number((event.target as HTMLInputElement).value)
}

const showFadeScale = ref(0)
const startFadeScaleAnimation = () => {
  setupAnimation()
  runAnimation()

  setInterval(() => {
    if (showFadeScale.value < 4) {
      showFadeScale.value++
    }
  }, 200) // Interval between animations
}

watch(
  () => props.infinityRoomInstallationControlsViewShown,
  async (newVal) => {
    if (newVal) {
      window.scrollTo({ top: 0, behavior: "smooth" })
      document.body.style.overflow = "hidden"

      startFadeScaleAnimation()
      await setupChannelAndStartSession()

      window.setTimeout(() => {
        const randomShow = Math.floor(Math.random() * 3) + 1
        setShow(randomShow)
      }, 500)

      watch(color, (newValue) => {
        selectColor(newValue)
      })

      watch(brightness, (newValue) => {
        selectBrightness(newValue)
      })

      channel.value?.on("disconnect", async () => {
        await stopSession()
      })
      channel.value?.on("stop_session", async () => {
        await stopSession()
      })
      channel.value?.on("ready", async (message) => {
        await transitionToControls()
      })
      channel.value?.on("idle", async (message) => {
        await stopSession()
      })
    }
  }
)

onMounted(async () => {})

const getShowTimeout = computed(() => {
  const showId = Number(show.value)
  if (Object.keys(showDurations).map(Number).includes(showId)) {
    return showDurations[showId]
  }
  return 0
})

const extendSession = async () => {
  await channel.value?.extend_session({})
}

const extendSessionForShow = async () => {
  if (getShowTimeout.value > 0) {
    await channel.value?.extend_session({
      custom_timeout: getShowTimeout.value,
    })
  }
}

const transitionToControls = async () => {
  extendSessionForShow()

  showShowSelection.value = false
}

const setupChannelAndStartSession = async () => {
  const sessionHash = route.query.h as string
  const installationPublicId: string | undefined =
    sessionHash?.substring(0, 8) || undefined
  if (
    installationPublicId === "wow_inf2" &&
    store.getUser?.anycable_jwt_token &&
    sessionHash?.length === 24
  ) {
    channel.value = await setupClientChannel(
      installationPublicId,
      store.getUser?.anycable_jwt_token,
      sessionHash
    )
    try {
      const message: StartSessionMessage = {
        jua_user_public_id: store.getUser?.public_id,
      }
      await channel.value.start_session(message)
    } catch (error) {
      console.error("An error occurred:", error)
      stopSession()
    }
  } else {
    console.error("Something went wrong...")
  }
}

const stopSession = async () => {
  console.log("stopSession")
  emit("hide")
  document.body.style.overflow = "scroll"
  cleanClientChannel()
}

onBeforeUnmount(() => {
  cleanClientChannel()
})

const canvas = ref()
const ctx = ref()
const vw = ref()
const vh = ref()

const setupAnimation = () => {
  canvas.value = document.createElement("canvas")
  canvas.value.classList.add("ripple")
  ctx.value = canvas.value.getContext("2d")
  ctx.value.clearRect(0, 0, vw.value, vh.value)
  vw.value = canvas.value.width = window.innerWidth
  vh.value = canvas.value.height = window.innerHeight
  document.body.appendChild(canvas.value)
}

function runAnimation() {
  createRipple("250,0,60", vw.value, vh.value)
  setTimeout(() => {
    infinityRoomInstallationControls.value.style.backgroundColor =
      "rgb(250,0,60)"
    removeCanvas()
  }, 500)
}

function createRipple(color, vw, vh) {
  const x = vw / 2
  // const y = vh / 2
  const y = (vh / 100) * 40 // 40vh

  const dx = x < vw / 2 ? vw - x : x
  const dy = y < vh / 2 ? vh - y : y

  const radius = Math.sqrt(dx * dx + dy * dy)

  const ripple = {
    alpha: 1,
    radius: 0,
    x: x,
    y: y,
  }

  const tl = gsap
    .timeline({ onUpdate: () => drawRipple(ripple, color) })
    .to(ripple, { alpha: 1, radius: radius, duration: 0.35 })
}

function drawRipple(ripple, color) {
  ctx.value.beginPath()
  ctx.value.arc(ripple.x, ripple.y, ripple.radius, 0, Math.PI * 2, false)
  ctx.value.fillStyle = `rgba(${color},${ripple.alpha})`
  ctx.value.fill()
}

function removeCanvas() {
  if (document.body.querySelectorAll("canvas.ripple")) {
    document.body.querySelectorAll("canvas.ripple").forEach((c) => {
      document.body.removeChild(c)
    })
  }
}

const numberOfUploadedSnaps = ref(0)
const isUploading = ref(false)

const handleSnap = async (e: any) => {
  window.scroll({ top: 0 })
  isUploading.value = true
  if (store.getUser && store.getJourney) {
    const file = e?.target?.files[0]
    if (file) {
      const a = await post.asset(store.getUser.public_id, file)
      if (a?.public_id) {
        window.setTimeout(() => removeIsUploadingIndicator(), 1000)
      } else {
        isUploading.value = false
        throw new UploadError()
      }
    } else {
      isUploading.value = false
      throw new SelectionError()
    }
  } else {
    isUploading.value = false
    throw new NoJourneyError()
  }
}

const removeIsUploadingIndicator = () => {
  numberOfUploadedSnaps.value += 1
  isUploading.value = false
}

const capturedPhotosString = computed(() => {
  if (t("wow_infinity_room.captured_photos")?.includes(" X ")) {
    return t("wow_infinity_room.captured_photos").replace(
      " X ",
      ` ${numberOfUploadedSnaps.value} `
    )
  }
  return ""
})
</script>

<style scoped lang="postcss">
.bubble {
  @apply select-none flex-grow-0 aspect-square odd:-left-12 even:-right-12 relative transition duration-700 bg-white rounded-full even:ml-auto odd:mr-auto;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  height: calc(100vh / 4.25);

  img {
    @apply select-none transition duration-700  border-4 border-white object-cover h-full w-full rounded-full overflow-hidden;
    transform: scale(1.25);
  }

  .label {
    @apply border-4 border-transparent transition duration-700 absolute top-1/2 -mt-6 w-60 text-xl font-bold text-wg2-red px-3 py-1 rounded-full bg-white;
    transform: scale(0.9);
  }
}

.bubble.active {
  @apply bg-black bg-opacity-30;

  img {
    animation: grow-pulse-lg 2s infinite;
    animation-timing-function: ease-in-out;
    transform: scale(1.35);
  }

  .label {
    @apply scale-100 bg-wg2-red text-white border-white;
    animation: grow-pulse-sm 2s infinite;
    animation-timing-function: ease-in-out;
  }
}

input {
  @apply w-full p-1 rounded-full appearance-none bg-transparent [&::-webkit-slider-runnable-track]:rounded-full [&::-webkit-slider-runnable-track]:bg-transparent [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:h-8 [&::-webkit-slider-thumb]:w-8 [&::-webkit-slider-thumb]:rounded-full [&::-webkit-slider-thumb]:bg-white;
  border-width: 3px;
  border-color: white;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease;
  transition-delay: 0.5s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@keyframes grow-pulse-lg {
  0% {
    transform: scale(1.25);
  }
  50% {
    transform: scale(1.45);
  }
  100% {
    transform: scale(1.25);
  }
}

@keyframes grow-pulse-md {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes grow-pulse-sm {
  0% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(0.9);
  }
}

@keyframes scale-over {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  70% {
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.fade-scale-enter-active {
  animation: scale-over 0.5s;
}

.fade-scale-enter-from,
.fade-scale-leave-to {
  opacity: 0;
  transform: scale(0.5);
}

.fade-scale-enter-to,
.fade-scale-leave-from {
  opacity: 1;
  transform: scale(1);
}

.inactive-button {
  @apply bg-wg2-red text-white border-white;
  border-color: rgba(255, 255, 255, 0.3);
  animation: blink-border 1s linear infinite; /* 1s duration, linear timing, infinite loop */
}

@keyframes blink-border {
  0%,
  100% {
    border-color: rgba(
      255,
      255,
      255,
      0.3
    ); /* Start and end with a transparent border */
  }
  50% {
    border-color: white; /* In the middle of the animation, the border will be white */
  }
}
</style>
