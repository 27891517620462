import { defineStore } from "pinia"
import { useStorage, RemovableRef } from "@vueuse/core"

import { Journey, journeySchema, User } from "@/api/types"
import { get, post } from "@/api/main"

const storePrefix = "wg2"

export const useWg2Store = defineStore(storePrefix, {
  state: () => ({
    journey: useStorage(`${storePrefix}/journey`, {}) as RemovableRef<Journey>,
    user: useStorage(`${storePrefix}/user`, {}) as RemovableRef<User>,
    onboardingCompleted: useStorage(
      `${storePrefix}/onboardingCompleted`,
      false
    ) as RemovableRef<boolean>,
  }),

  actions: {
    async setJourney(storyVersionPublicId: string) {
      this.journey = await post.journey(storyVersionPublicId)
    },
    async setUser(journeyPublicId: string) {
      this.user = await post.user(journeyPublicId)
    },
    async refreshUser() {
      this.user = await get.user(this.user.public_id)
    },
    async initializeAnonymousJuaUserWithJourney(storyVersionPublicId: string) {
      try {
        await this.setJourney(storyVersionPublicId)
        if (this.journey) {
          await this.setUser(this.journey.public_id)
        }
      } catch (error) {
        console.error(error)
        return error
      }
    },
    async setOnboardingCompleted() {
      this.onboardingCompleted = true
    },
  },

  getters: {
    getJourney: (state): Journey | undefined => {
      return state.journey
    },
    getUser: (state): User | undefined => {
      return state.user
    },
    getOnboardingCompleted: (state): Boolean => {
      return state.onboardingCompleted
    },
  },
})
