export const localizeDate = (
  locale: string,
  ts: string,
  type: string = "default"
): string => {
  const n = new Date(ts)

  const defaultConfig = {
    date: {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    },
    time: {
      hour: "2-digit",
      minute: "2-digit",
    },
  }

  const localeConfig = {
    default: {
      de: {
        locale: "de-CH",
        template: "%s · %s Uhr",
        ...defaultConfig,
      },
      fr: {
        locale: "fr-CH",
        template: "%s · %s",
        ...defaultConfig,
      },
      it: {
        locale: "de-CH",
        template: "%s · %s",
        ...defaultConfig,
      },
      en: {
        locale: "en-US",
        template: "%s · %s",
        ...defaultConfig,
      },
    },
    withoutTime: {
      de: {
        locale: "de-CH",
        template: "%s",
        ...defaultConfig,
      },
      fr: {
        locale: "fr-CH",
        template: "%s",
        ...defaultConfig,
      },
      it: {
        locale: "de-CH",
        template: "%s",
        ...defaultConfig,
      },
      en: {
        locale: "en-US",
        template: "%s",
        ...defaultConfig,
      },
    },
  }

  if (
    Object.keys(localeConfig).includes(type) &&
    Object.keys(localeConfig[type]).includes(locale)
  ) {
    const config = localeConfig[type][locale]
    const d = n.toLocaleDateString(config.locale, config.date)
    const t =
      type === "default" ? n.toLocaleTimeString(config.locale, config.time) : ""
    return interpolate(config.template, [d, t])
  }
  return n.toLocaleString()
}

const interpolate = (theString: string, argumentArray: any) => {
  var regex = /%s/
  var _r = function (p: any, c: any) {
    return p.replace(regex, c)
  }
  return argumentArray.reduce(_r, theString)
}
