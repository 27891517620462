import { StoryVersion } from "@/api/types"

export default async (
  to: any,
  from: any,
  next: any,
  projectConfig: any,
  rawStoryVersion: StoryVersion
) => {
  const store = useDefaultStore()
  // store.isInitialized = false
  // console.log(store.isInitialized)
  // store.initApp()
  // console.log(store.isInitialized)

  // console.log(rawStoryVersion.data.project.short_id)
}
