import { AppError } from "./app_error"

class UserCaptureError extends AppError {
  constructor(silent: boolean = false) {
    super(silent)
    this.name += "UserCapture."
  }
}

export class UploadError extends UserCaptureError {
  constructor() {
    super()
    this.name += "UploadError"
  }
}

export class SelectionError extends UserCaptureError {
  constructor() {
    super()
    this.name += "SelectionError"
  }
}

export class NoJourneyError extends UserCaptureError {
  constructor() {
    super()
    this.name += "NoJourneyError"
  }
}
