<template>
  <component v-if="layout" :is="layout">
    <component
      v-if="sceneComponent"
      :is="sceneComponent"
      :scene="scene"
      :scenes="scenes"
      :storyVersion="storyVersion"
      :projectConfig="projectConfig"
      :assetGroups="assetGroups"
      :locales="locales"
    />
  </component>
</template>

<script setup lang="ts">
import { ref, computed, defineAsyncComponent, onMounted } from "vue"
import {
  StoryVersion,
  storyVersionSchema,
  Scene,
  ScenesMap,
  AssetGroupsMap,
  ProjectConfig,
  projectConfigSchema,
} from "@/api/types"
import projects from "@/config/projects"

const store = useStore()
const route = useRoute()

const storyVersionPublicId = ref(route.params.storyVersionPublicId as string)
const sceneId = ref(route.params.sceneId as string)
const locales = ref()
const storyVersion = ref<StoryVersion>()
const scenes = ref<ScenesMap>()
const scene = ref<Scene>()
const assetGroups = ref<AssetGroupsMap>()
const projectConfig = ref<ProjectConfig>()

const sceneComponent = computed(() => {
  const projectShortId = projectConfig.value?.short_id || "default"
  if (Object.keys(projects).includes(projectShortId)) {
    return defineAsyncComponent(async () => {
      return await import(`../components/projects/${projectShortId}/scene.vue`)
    })
  }
  return false
})

const layout = computed(() => {
  const projectShortId = projectConfig.value?.short_id || "default"
  if (Object.keys(projects).includes(projectShortId)) {
    return defineAsyncComponent(async () => {
      return await import(`../layouts/projects/${projectShortId}/default.vue`)
    })
  }
  return false
})

onMounted(async () => {
  document.body.style.overflow = "scroll"

  const rawStoryVersion = await storyVersionSchema.validate(
    JSON.parse(
      JSON.stringify(await store.getStoryVersion(storyVersionPublicId.value))
    )
  )
  const projectShortId = rawStoryVersion.data.project.short_id

  projectConfig.value = await projectConfigSchema.validate({
    short_id: projectShortId,
    config: projects[projectShortId],
  })

  locales.value = rawStoryVersion.data.story.locales
  storyVersion.value = rawStoryVersion
  if (rawStoryVersion.data.story.scenes?.length) {
    const scenesMap = Object.fromEntries(
      rawStoryVersion.data.story.scenes as any
    )
    scenes.value = scenesMap
    scene.value = scenesMap[sceneId.value]
    if (scene.value?.asset_groups?.length) {
      const assetGroupsMap = Object.fromEntries(
        scene.value?.asset_groups as any
      )
      assetGroups.value = assetGroupsMap
    }
  }

  // findActions(storyVersion.value)
})

// const sceneNavigation = ref({
//   child: undefined,
//   parent: undefined,
//   next: undefined,
//   previous: undefined,
// })

// const findActions = (d) => {
//   const sequenceIndex = (sp) => parseInt(sp.split("_").pop())
//   const sequenceDepth = (sp) => parseInt(sp.split("_").length)
//   const sequencePath = (sp) => sp.split("_").slice(0, -1).join("_")
//   const path = scene.value.scene_data.path
//   const currentSceneIndex = sequenceIndex(path)
//   const currentSceneDepth = sequenceDepth(path)
//   const currentSceneParentPath = sequencePath(path)
//   for (const scene of d.scene_versions) {
//     const sp = scene.scene_data.path
//     const sceneIndex = sequenceIndex(sp)
//     const sceneDepth = sequenceDepth(sp)
//     const sceneParentPath = sequencePath(sp)
//     if (
//       currentSceneDepth === sceneDepth &&
//       currentSceneParentPath === sceneParentPath
//     ) {
//       if (currentSceneIndex + 1 === sceneIndex) {
//         sceneNavigation.value.next = scene
//       } else if (currentSceneIndex - 1 === sceneIndex) {
//         sceneNavigation.value.previous = scene
//       }
//     } else if (currentSceneParentPath === sp) {
//       sceneNavigation.value.parent = scene
//     } else if (path === sceneParentPath && sceneIndex === 0) {
//       sceneNavigation.value.child = scene
//     }
//   }
// }
</script>
