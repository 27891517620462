export const onDecode = (
  decodedString: string,
  router: any,
  locale: string
) => {
  // console.log("decodedString", decodedString)
  if (decodedString === "settings" || decodedString.includes("/settings")) {
    router.push({
      name: "settings",
    })
  } else if (
    (decodedString.length === 8 && decodedString.startsWith("debug_")) ||
    decodedString.includes("/bug_report/debug_")
  ) {
    if (
      decodedString.length !== 8 &&
      decodedString.includes("/bug_report/debug_")
    ) {
      // we need to extract bugReportQrCode from the decodedString
      let url: URL = new URL(decodedString)
      let parts = url?.pathname?.split("/")
      if (parts) {
        const length = parts.length
        // This retrieves the last part. If the URL ends with a '/', this will be an empty string, so it falls back to the second to last part
        const newDecodedString = parts[length - 1] || parts[length - 2]
        if (newDecodedString?.length === 8) {
          decodedString = newDecodedString
        }
      }
    }

    router.push({
      name: "bug_report",
      params: { bugReportQrCode: decodedString, internalQrCodeScanner: "1" }, // internal
    })
  } else if (
    decodedString.includes("wow-guide.ch/iframes/extras/infinity_room") ||
    decodedString.includes("wow-guide.ch/iframes/extras/test_s01")
  ) {
    window.location.href = decodedString
  } else if (decodedString.includes("wow-guide.ch/e/")) {
    // legacy interactive iframe installation qr codes are:
    // https://wow-guide.ch/e/piano

    const legacyIframeInstallationId = decodedString
      .toString()
      .replace(/\/$/, "") // remove trailing slash if it exists
      .split("/")
      .slice(-1)[0] // get only the id

    if (legacyIframeInstallationId) {
      const m = {
        fyr: "xmas",
        draw: "draw",
        piano: "piano",
        drums: "drums",
      }
      if (Object.keys(m).includes(legacyIframeInstallationId)) {
        const slug = m[legacyIframeInstallationId]
        router.push({
          name: "wg2_i_frame",
          params: { locale, slug },
        })
      } else {
        console.error("Could not decode... ")
      }
    } else {
      console.error("Could not decode... ")
    }
  } else if (decodedString.includes("wow-guide.ch/r/")) {
    // legacy scanpoint qr codes are:
    // https://wow-guide.ch/r/12
    const legacyScanpointId = decodedString
      .toString()
      .replace(/\/$/, "") // remove trailing slash if it exists
      .split("/")
      .slice(-1)[0] // get only the id
    if (legacyScanpointId) {
      const m = {
        "1": "wow_sp01",
        "2": "wow_sp02",
        "3": "wow_sp03",
        "4": "wow_sp04",
        "5": "wow_sp05",
        "6": "wow_sp06",
        "7": "wow_sp07",
        "8": "wow_sp08",
        "9": "wow_sp09",
        "10": "wow_sp10",
        "11": "wow_sp11",
        "12": "wow_sp12",
        "13": "wow_sp13",
        "14": "wow_sp14",
        "15": "wow_sp15",
        "16": "wow_sp16",
        "17": "wow_sp17",
        "18": "wow_sp18",
        "19": "wow_sp19",
        "20": "wow_sp20",
        "21": "wow_sp21",
        "22": "wow_sp22",
        "23": "wow_sp23",
        "24": "wow_sp24",
        "25": "wow_sp25",
        "26": "wow_sp26",
        "27": "wow_sp27",
        "28": "wow_sp28",
        "29": "wow_sp29",
        "30": "wow_sp30",
        "31": "wow_sp31",
        "32": "wow_sp32",
        "33": "wow_sp33",
        "34": "wow_sp34",
        "35": "wow_sp35",
        "36": "wow_sp36",
        "37": "wow_sp37",
        "38": "wow_sp38",
        "39": "wow_sp39",
        "40": "wow_sp40",
        "41": "wow_sp41",
        "42": "wow_sp42",
        "43": "wow_sp43",
        "44": "wow_sp44",
        "45": "wow_sp45",
        "46": "wow_sp46",
        "47": "wow_sp47",
        "48": "wow_sp48",
        "49": "wow_sp49",
        "50": "wow_sp50",
        "51": "wow_sp51",
        "52": "wow_sp52",
        "53": "wow_sp53",
        "54": "wow_sp54",
        "55": "wow_sp55",
        "56": "wow_sp56",
        "57": "wow_sp57",
        "58": "wow_sp58",
        "59": "wow_sp59",
        "60": "wow_sp60",
        "61": "wow_sp61",
        "62": "wow_sp62",
        "63": "wow_sp63",
        "64": "wow_sp64",
        "65": "wow_sp65",
        "66": "wow_sp66",
        "67": "wow_sp67",
        "68": "wow_sp68",
        "69": "wow_sp69",
        "70": "wow_sp70",
        "71": "wow_sp71",
        "72": "wow_sp72",
        "73": "wow_sp73",
        "74": "wow_sp74",
        "75": "wow_sp75",
        "76": "wow_sp76",
        "77": "wow_sp77",
        "78": "wow_sp78",
        "79": "wow_sp79",
        "80": "wow_sp80",
        "81": "wow_sp81",
        "82": "wow_sp82",
        "83": "wow_sp83",
        "84": "wow_sp84",
        "85": "wow_sp85",
        "86": "wow_sp86",
        "87": "wow_sp87",
        "88": "wow_sp88",
        "89": "wow_sp89",
        "90": "wow_sp90",
        "91": "wow_sp91",
        "92": "wow_sp92",
        "93": "wow_sp93",
        "94": "wow_sp94",
        "95": "wow_sp95",
        "96": "wow_sp96",
        "97": "wow_sp97",
        "98": "wow_sp98",
        "99": "wow_sp99",
      }
      if (Object.keys(m).includes(legacyScanpointId)) {
        const scanpointPublicId = m[legacyScanpointId]
        router.push({
          name: "scanpoint_entrypoint",
          params: {
            locale,
            scanpointPublicId,
          },
        })
      } else {
        console.error("Could not decode... ")
      }
    } else {
      console.error("Could not decode... ")
    }
  } else if (decodedString.includes("/s/")) {
    const scanpointPublicId = decodedString
      .toString()
      .split("/")
      .slice(-2)
      .reverse()
      .pop()
    // console.log("scanpointPublicId", scanpointPublicId)
    if (scanpointPublicId?.length === 8) {
      router.push({
        name: "scanpoint_entrypoint",
        params: { locale, scanpointPublicId },
      })
    } else {
      console.error("Could not decode... ")
    }
  } else if (decodedString.includes("/i/")) {
    const sessionHash = decodedString
      .toString()
      .replace(/\/$/, "") // remove trailing slash if it exists
      .split("/")
      .slice(-1)
      .reverse()
      .pop()
    console.log("sessionHash", sessionHash)
    if (
      sessionHash?.length === 24 ||
      (sessionHash?.length === 24 && sessionHash?.startsWith("wow_inf2")) ||
      (sessionHash?.length === 25 && sessionHash?.startsWith("wow_you2"))
    ) {
      router.push({
        name: "installation_entrypoint",
        params: {
          locale,
          sessionHash,
        },
      })
    } else {
      console.error("Could not decode... ")
    }
  } else {
    console.error("Could not decode... ")
  }
}
