<template>
  <Layout>
    <div class="flex flex-col pt-24">
      <div class="fixed right-6 top-6 flex justify-end items-center">
        <div class="flex gap-x-4">
          <router-link
            :to="{
              name: 'wg2_journey',
              params: { locale: route.params.locale, projectShortId: 'wg2' },
            }"
            class="rounded-full bg-wg2-red h-14 w-14 text-white flex justify-center items-center active:scale-125"
          >
            <div
              class="w-12 h-12 border-2 border-white bg-wg2-red flex justify-center rounded-full text-4xl items-center"
            >
              <span class="material-symbols-rounded">close</span>
            </div>
          </router-link>
        </div>
      </div>
      <div class="mb-32">
        <div v-if="activity" class="group">
          <component
            :is="activityKindComponent(activity?.kind)"
            :activity="activity"
            :shared="false"
          />
        </div>
      </div>
    </div>
  </Layout>
</template>

<script setup lang="ts">
import Layout from "@/layouts/projects/wg2/default.vue"
import { Activity } from "@/api/types"

const route = useRoute()
const router = useRouter()
const store = useWg2Store()

const activity = ref<Activity>()

onMounted(async () => {
  document.body.style.overflow = "scroll"

  await store.refreshUser()
  activity.value = store?.getUser?.activities?.find(
    (x) => x.public_id === route.params.publicId
  )
})

const activityKindComponent = (kind: string) => {
  let l = "asset"
  if (kind === "???") {
    l = "???"
  }
  return defineAsyncComponent(
    () =>
      import(`../../../components/projects/wg2/activity_kinds/main/${l}.vue`)
  )
}
</script>

<style scoped lang="postcss">
.action-button {
  @apply bg-wg2-red px-5 py-1 text-lg rounded-full text-white font-bold inline-block;
}
</style>
