export const cableUrlFor = (
  token: string,
  session_hash: string = ""
): string => {
  console.log("MODE", import.meta.env.MODE)
  const staging = window.location.hostname.includes("staging")

  if (
    ["test", "systemtestlocal", "systemtestremote"].includes(
      import.meta.env.MODE
    )
  ) {
    return `ws://${window.location.hostname}:9000/cable?jid=${token}&session_hash=${session_hash}`
  } else {
    return import.meta.env.MODE === "development"
      ? // use below for anycable # manual_override_for_anycable_secret
        `ws://localhost:8080/cable?jid=${token}&session_hash=${session_hash}`
      : // use below for actioncable
      // `ws://localhost:3000/cable?jid=${token}&session_hash=${session_hash}`
      staging
      ? `wss://staging-naut-brdge-v1-anycable-go.c.kitesrv.com/cable?jid=${token}&session_hash=${session_hash}`
      : `wss://naut-brdge-v1-anycable-go.c.kitesrv.com/cable?jid=${token}&session_hash=${session_hash}`
  }
}
