import { createCable } from "@anycable/web"
import { cableUrlFor } from "./cable_url"
import { ClientChannel } from "./client_channel"
import { Cable } from "@anycable/core"

// Shared state to store the cable and channel
let sharedCable: Cable | null = null
let sharedChannel: ClientChannel | null = null

export const setupClientChannel = async (
  installationPublicId: string,
  juaUserToken: string,
  sessionHash: string
): Promise<ClientChannel> => {
  // If channel already exists, reset
  if (sharedChannel !== null) {
    try {
      sharedChannel.disconnect()
    } catch (error) {
      console.error("Error during channel reset: ", error)
    } finally {
      sharedChannel = null
    }
  }
  // If cable already exists, reset
  if (sharedCable !== null) {
    try {
      sharedCable.disconnect()
    } catch (error) {
      console.error("Error during cable reset: ", error)
    } finally {
      sharedCable = null
    }
  }

  // Else, create new channel and cable
  sharedChannel = new ClientChannel({
    installation_public_id: installationPublicId,
  })

  sharedCable = createCable(cableUrlFor(juaUserToken, sessionHash))
  sharedCable.subscribe(sharedChannel)

  sharedChannel.ensureSubscribed()
  sharedChannel.on("connect", () => console.log("Connected"))
  sharedChannel.on("disconnect", () => console.log("No connection"))
  sharedChannel.on("close", () => console.log("Disconnected"))

  return sharedChannel
}

export const cleanClientChannel = async () => {
  try {
    sharedChannel?.disconnect()
    sharedCable?.disconnect()
  } catch (error) {
    console.error("Error during channel/cable cleanup: ", error)
  } finally {
    sharedChannel = null
    sharedCable = null
  }
}
