<template>
  <Layout>
    <div class="flex flex-col">
      <!-- <div class="flex justify-end items-center p-8">
        <div class="flex gap-x-4">
          <div
            class="rounded-full bg-wg2-red h-14 w-14 text-white flex justify-center items-center active:scale-125"
            @click="shareJourney"
          >
            <div
              class="w-12 h-12 border-2 border-white bg-wg2-red flex justify-center rounded-full items-center"
            >
              <span class="material-symbols-rounded pr-1">share</span>
            </div>
          </div>
        </div>
      </div> -->
      <div class="p-8 flex flex-col">
        <div class="flex justify-between items-center">
          <h1 class="text-2xl uppercase text-wg2-red font-bold mt-2">
            {{ t("journey.title") }}
          </h1>
          <div class="fixed right-6 top-6">
            <div
              class="flex rounded-full bg-wg2-red h-14 w-14 text-white justify-center items-center active:scale-125"
              @click="toggleJourneyHelp"
              v-if="
                groupedFilteredActivities &&
                Object.keys(groupedFilteredActivities).length
              "
            >
              <div
                class="rounded-full w-12 h-12 border-2 border-white bg-wg2-red flex justify-center items-center"
              >
                <span class="material-symbols-rounded">question_mark</span>
              </div>
            </div>
          </div>
        </div>

        <Transition name="slide-fade">
          <div
            v-if="
              (groupedFilteredActivities &&
                !Object.keys(groupedFilteredActivities).length) ||
              showJourneyHelp
            "
            class="text-wg2-red"
          >
            <div class="text-lg mt-6 mb-8">
              {{ t("journey.text") }}
            </div>
            <div class="text-lg mt-3 mb-8" v-if="expiryDate instanceof Date">
              {{ t("journey.shared_link_expiry") }}
              {{ localizedExpiryDate() }}
            </div>

            <div>
              <button class="action-button" @click="shareJourney">
                {{ t("journey.create_journey_link") }}
              </button>
            </div>
          </div>
        </Transition>
      </div>

      <div>
        <div class="px-8 mb-12">
          <div
            v-for="(activities, group) of groupedFilteredActivities"
            class="group"
            data-test="activity-group"
          >
            <div
              v-if="activities?.filter((a: Activity) => a?.index < maxIndex).length > 0"
            >
              <div class="font-lg text-wg2-red text-xl font-bold mb-2">
                {{ groupTitle(group) }}
              </div>

              <div
                v-for="activity in activities?.filter((a: Activity) => a?.index < maxIndex)"
                data-test="activity"
              >
                <component
                  :is="activityKindComponent(activity?.kind)"
                  :activity="activity"
                  :shared="false"
                  :to="{
                    name: 'wg2_activity',
                    params: {
                      locale: route.params.locale,
                      projectShortId: 'wg2',
                      publicId: activity.public_id,
                    },
                  }"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pb-16 px-8">
        <button v-if="hasMoreToLoad" class="action-button" @click="loadMore">
          {{ t("journey.show_more") }}
        </button>
      </div>
      <div class="pb-40 px-8">
        <button
          v-if="
            groupedFilteredActivities &&
            Object.keys(groupedFilteredActivities).length
          "
          class="action-button"
          @click="shareJourney"
        >
          {{ t("journey.create_journey_link") }}
        </button>
      </div>
    </div>
  </Layout>
</template>

<script setup lang="ts">
import { defineAsyncComponent } from "vue"
import Layout from "@/layouts/projects/wg2/default.vue"

import { post } from "@/api/main"
import { Activity } from "@/api/types"
import { localizeDate } from "@/helpers/date"

import { t } from "@/helpers/i18n"

const showJourneyHelp = ref(true)

const page = ref(0)
const pageSize = 10
const maxIndex = computed(() => page.value * pageSize + pageSize)

const expiryDate = ref()

const localizedExpiryDate = () =>
  localizeDate(route?.params?.locale || "de", expiryDate.value, "withoutTime")

const loadMore = () => {
  page.value += 1
  window.setTimeout(
    () =>
      window.scroll({ top: document.body.scrollHeight, behavior: "smooth" }),
    300
  )
}

const hasMoreToLoad = computed(() => {
  const lastIndex = Object.keys(filteredActivities?.value || {}).length
  return lastIndex > maxIndex.value
})

const toggleJourneyHelp = () => {
  const top = window.pageYOffset || document.documentElement.scrollTop || 0
  if ((top === 0 && showJourneyHelp.value) || !showJourneyHelp.value) {
    showJourneyHelp.value = !showJourneyHelp.value
  }
  window.scroll({ top: 0, behavior: "smooth" })
}

const route = useRoute()
const router = useRouter()
const store = useWg2Store()

const groupBy = (list, keyGetter) => {
  const map = new Map()
  list.forEach((item) => {
    const key = keyGetter(item)
    const collection = map.get(key)
    if (!collection) {
      map.set(key, [item])
    } else {
      collection.push(item)
    }
  })
  return map
}

onMounted(async () => {
  document.body.style.overflow = "scroll"

  await store.refreshUser()
})

const filteredActivities = computed(() => {
  if (store?.getUser?.jua_journey_visited_at) {
    const visitedDate = new Date(store?.getUser?.jua_journey_visited_at)
    const calculatedExpiryDate = new Date(visitedDate.getTime() + 12096e5)
    expiryDate.value = calculatedExpiryDate || new Date(Date.now() + 12096e5) // fallback
  }

  if (store?.getUser?.activities) {
    return store?.getUser?.activities
      .filter((x) =>
        ["wg2_installation_capture", "wg2_user_capture"].includes(x?.kind)
      )
      .map((a, index) => ({ ...a, index }))
  } else {
    return {}
  }
})

const groupedFilteredActivities = computed(() => {
  if (filteredActivities) {
    const filtered = filteredActivities.value || {}
    // filtered?.push({
    //   kind: "wg2_installation_capture",
    //   public_id: "a",
    //   data: {
    //     group: "a",
    //     type: "photo",
    //     url: "https://picsum.photos/id/237/200/300",
    //   },
    // })
    const grouped = groupBy(filtered, (i: Activity) => i?.data?.group)
    return Object.fromEntries(Array.from(grouped))
  } else {
    return {}
  }
})

const groupTitle = (g: string) => {
  const m = {
    wow_ames: "AMES",
    wow_flkr: "FLICKER",
    wow_crcl: "CIRCLE",
    wow_twst: "TWIST",
    wow_you2: "WOW YOU",
    user_capture: "MADE BY YOU",
  }
  if (Object.keys(m).includes(g)) {
    return m[g]
  }
  return g
}

const activityKindComponent = (kind: string) => {
  let l = "asset"
  if (kind === "???") {
    l = "???"
  }
  return defineAsyncComponent(
    () =>
      import(`../../../components/projects/wg2/activity_kinds/list/${l}.vue`)
  )
}

const shareJourney = () => {
  const sharePath = router.resolve({
    name: "wg2_shared_journey",
    params: { journeyPublicId: store.journey.public_id },
  }).href
  const url = `${import.meta.env.VITE_FRONTEND_BASE}${sharePath}`
  console.log(url)
  if (navigator?.share) {
    navigator
      .share({
        url,
      })
      .then(() => {
        console.log("Thanks for sharing!")
      })
      .catch(console.error)
  } else {
    // fallback
  }
}
</script>

<style scoped lang="postcss">
.action-button {
  @apply bg-wg2-red px-5 py-1 text-lg rounded-full text-white font-bold inline;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.7s ease-in-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}
</style>
