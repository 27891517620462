import { get } from "@/api/main"
import { storyVersionSchema, StoryVersion } from "@/api/types"

export const i18n = (
  v: any,
  storyVersion: StoryVersion | undefined,
  locale: string | string[]
) => {
  if (v && storyVersion) {
    const l = locale as string
    const dl = storyVersion.data.story.default_locale as string
    if (Object.keys(v).includes(l)) {
      return v[l]
    } else if (Object.keys(v).includes(dl)) {
      return v[dl]
    }
  }
  return "VALUE NOT FOUND"
}

export const t = (k: string) => {
  const fallbackLocale = "de"

  const route = useRoute()
  const store = useStore()

  const locale = (route?.params?.locale as string) || fallbackLocale
  const storyVersion = store.getCurrentStoryVersion

  const g = (target: Object, keypath: string) => {
    if (!target || target === undefined) {
      return keypath
    }
    try {
      return keypath
        .split(".")
        .reduce((previous, current) => previous[current], target)
    } catch (error) {
      console.log("error in t", { target, keypath })
      return keypath
    }
  }

  const strings = storyVersion?.data.project.strings
  if (strings && Object?.keys(strings)?.includes(locale)) {
    const string = g(strings[locale], k) || k
    return string
  }
  return k

  // console.log(locale, k)
  // if (storyVersionPublicId !== undefined) {
  //   const rawStoryVersion = await storyVersionSchema.validate(
  //     JSON.parse(
  //       JSON.stringify(await store.getStoryVersion(storyVersionPublicId))
  //     )
  //   )
  //   console.log(rawStoryVersion.strings)
  //   const string = "rawStoryVersion.strings[locale][k]"
  //   return string
  // } else if (projectShortId !== undefined) {
  //   const sv = await get.publishedStoryVersion(projectShortId)
  //   const string = sv.strings[locale][k]
  //   return string
  // } else {
  //   console.error("Could not fetch locale data")
  // }

  return "a"
}
