import { defineStore } from "pinia"
import { useStorage } from "@vueuse/core"

const storePrefix = "wg2"

export const useDefaultStore = defineStore(storePrefix, {
  state: () => ({
    isInitialized: useStorage(`${storePrefix}/isInitialized`, false),
  }),

  actions: {
    initApp() {
      this.isInitialized = true
    },
  },

  getters: {
    isReady: (state) => {
      return !state.isInitialized
    },
  },
})
