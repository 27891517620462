<template>
  <div>
    <Transition name="fade">
      <div v-if="qrCodeViewShown">
        <QrCodeReader @hide="hideQrCodeReader" />
      </div>
    </Transition>

    <InstallationControls
      :installation-controls-view-shown="installationControlsViewShown"
      @hide="hideInstallationControls"
    />

    <WowYouInstallationControls
      :installation-controls-view-shown="wowYouInstallationControlsViewShown"
      @hide="hideInstallationControls"
    />

    <InfinityRoomInstallationControls
      :infinity-room-installation-controls-view-shown="
        infinityRoomInstallationControlsViewShown
      "
      @hide="hideInstallationControls"
    />

    <slot />

    <Actions
      v-if="!hideActions"
      :shown="actionsShown"
      :snap-shown="!hideFoo"
      :journey-shown="!hideFoo"
      @show-snap="showSnap"
      @show-qr-code-reader="showQrCodeReader"
      @show-journey="showJourney"
    />
  </div>
</template>

<script setup lang="ts">
import QrCodeReader from "@/components/projects/wg2/qr_code_reader_newer.vue"
import Actions from "@/components/projects/wg2/actions.vue"
import InstallationControls from "@/components/projects/wg2/installation_controls.vue"
import WowYouInstallationControls from "@/components/projects/wg2/wow_you_installation_controls.vue"
import InfinityRoomInstallationControls from "@/components/projects/wg2/infinity_room_installation_controls.vue"

const route = useRoute()
const router = useRouter()

const props = defineProps({
  hideActions: { type: Boolean, default: false },
  hideFoo: { type: Boolean, default: false },
  showActions: { type: Boolean, default: true },
})

const emit = defineEmits(["show-qr-code-reader"])

onMounted(() => {
  actionsShown.value = props.showActions
  if (route.query?.h?.length === 24 && route.query?.h?.startsWith("wow_inf2")) {
    showInfinityRoomInstallationControls()
  } else if (route.query?.h?.length === 24) {
    showInstallationControls()
  } else if (
    route.query?.h?.length === 25 &&
    route.query?.h?.startsWith("wow_you2")
  ) {
    showWowYouInstallationControls()
  }
})

const actionsShown = ref(false)

const qrCodeViewShown = ref(false)
const installationControlsViewShown = ref(false)
const wowYouInstallationControlsViewShown = ref(false)
const infinityRoomInstallationControlsViewShown = ref(false)

const showQrCodeReader = () => {
  qrCodeViewShown.value = true
  actionsShown.value = false
  emit("show-qr-code-reader")
}
const hideQrCodeReader = () => {
  qrCodeViewShown.value = false
  actionsShown.value = true
}

const showSnap = () => {
  router.push({ name: "wg2_snap", projectShortId: "wg2" })
}

const showJourney = () => {
  router.push({ name: "wg2_journey", projectShortId: "wg2" })
}

const showInstallationControls = () => {
  installationControlsViewShown.value = true
  actionsShown.value = false
}
const showWowYouInstallationControls = () => {
  wowYouInstallationControlsViewShown.value = true
  actionsShown.value = false
}
const showInfinityRoomInstallationControls = () => {
  infinityRoomInstallationControlsViewShown.value = true
  actionsShown.value = false
}
const hideInstallationControls = () => {
  installationControlsViewShown.value = false
  wowYouInstallationControlsViewShown.value = false
  infinityRoomInstallationControlsViewShown.value = false
  actionsShown.value = true
  router.replace({ query: {} })
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
