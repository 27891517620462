import defaultBeforeHook from "@/hooks/projects/default"
import wg2BeforeHook from "@/hooks/projects/wg2"

export default {
  default: {
    primaryColor: "#000000",
    publicTitle: "Default",
    defaultLocale: "de",
    beforeHook: defaultBeforeHook,
  },
  bbp: {
    primaryColor: "#E3000E",
    publicTitle: "bbp",
    defaultLocale: "de",
    beforeHook: async (
      to: any,
      from: any,
      next: any,
      projectConfig: any,
      rawStoryVersion: any
    ) => {
      console.log("bbp")
    },
  },
  wg2: {
    primaryColor: "#ff003c",
    publicTitle: "WOW-Guide",
    defaultLocale: "de",
    beforeHook: wg2BeforeHook,
  },
  nb2: {
    primaryColor: "#005ca9",
    publicTitle: "nb2",
    defaultLocale: "de",
    beforeHook: async (
      to: any,
      from: any,
      next: any,
      projectConfig: any,
      rawStoryVersion: any
    ) => {
      console.log("nb2")
    },
  },
}
