import { object, string, tuple, bool, InferType, array, lazy } from "yup"

export const successSchema = object({
  success: bool().isTrue(),
})

export const bugReportSchema = object({
  kind: string().required(),
  tag: string().nullable(),
  public_id: string(),
  device_info: object({
    user_agent: string(),
    internal_qr_code_scanner: string(),
    local_storage: object({
      wg2: object({
        journey: object({
          public_id: string(),
        }),
        user: object({
          public_id: string(),
        }),
      }),
    }),
  }),
  message: string(),
})

const assetGroupSchema = object({
  kind: string().required(),
  localized: bool(),
  assets: object({}),
  asset_variants: object({}),
})

const sceneSchema = object({
  kind: string().required(),
  asset_groups: array(tuple([string().required(), assetGroupSchema])),
  navigation_options: object({
    parent: bool(),
    previous: bool(),
    next: bool(),
    child: bool(),
  }),
  text: object({}),
  title: object({}),
})

const storyScanpointSchema = object({})

export const projectConfigSchema = object({
  short_id: string().required(),
  config: object({ publicTitle: string(), defaultLocale: string() }),
})

export const storyVersionSchema = object({
  public_id: string().required(),
  data: object({
    project: object({
      short_id: string().required(),
      strings: object(),
    }),
    story: object({
      default_locale: string().required(),
      locales: array(string()).required(),
      scanpoints: array(tuple([string().required(), storyScanpointSchema])),
      scenes: array(tuple([string().required(), sceneSchema])),
    }),
  }),
})

export const scanpointSchema = object({
  public_id: string().required(),
  scene_id: string().nullable(),
  published_story_version: storyVersionSchema,
})

export const installationSchema = object({
  public_id: string().required(),
  scene_id: string().nullable(),
  published_story_version: storyVersionSchema,
})

export const journeySchema = object({
  public_id: string().required(),
  config: object(),
  visited_at: string().required(),
})

export const activitySchema = object({
  public_id: string().required(),
  kind: string().required(),
  created_at: string().required(),
  data: object({
    group: string(),
  }),
})

export const assetSchema = object({
  public_id: string().required(),
  data: object(),
})

export const userSchema = object({
  public_id: string().required(),
  jua_journey_visited_at: string().required(),
  anycable_jwt_token: string().required(),
  email: string(),
  activities: array(activitySchema),
})

export type ProjectConfig = InferType<typeof projectConfigSchema>
export type StoryVersion = InferType<typeof storyVersionSchema>
export type Scanpoint = InferType<typeof scanpointSchema>
export type Installation = InferType<typeof installationSchema>
export type Scene = InferType<typeof sceneSchema>
export type AssetGroup = InferType<typeof assetGroupSchema>
export type Journey = InferType<typeof journeySchema>
export type User = InferType<typeof userSchema>
export type Activity = InferType<typeof activitySchema>
export type BugReport = InferType<typeof bugReportSchema>

export type StoryVersionsMap = {
  [key: string]: StoryVersion
}

export type ScenesMap = {
  [key: string]: Scene
}

export type AssetGroupsMap = {
  [key: string]: AssetGroup
}

export type ActivitiesMap = {
  [key: string]: Activity
}
