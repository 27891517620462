<template>
  <div class="pulsedot"></div>
</template>

<script setup lang="ts">
// import { Vue3Lottie } from "vue3-lottie"
// import RedSpinnerJSON from "@/assets/projects/wg2/animations/red-spinner.json"

import { ref, onMounted } from "vue"

import { get } from "@/api/main"
import { StoryVersion, storyVersionSchema } from "@/api/types"

const route = useRoute()
const router = useRouter()
const store = useStore()

const storyVersionPublicId = ref(route.params.storyVersionPublicId as string)

const getStoryVersion = async () => {
  const storyVersion = await get.storyVersion(storyVersionPublicId.value)
  if (storyVersion.data.story.scenes?.length) {
    return storyVersion
  }
}

const getSceneId = (storyVersion: StoryVersion) => {
  if (storyVersion.data.story.scenes?.length) {
    const firstId = storyVersion.data.story.scenes[0]?.[0] as string
    if (firstId?.length >= 1) {
      return firstId
    }
  }
}

const getDefaultLocale = (storyVersion: StoryVersion) => {
  if (storyVersion.data.story.default_locale) {
    return storyVersion.data.story.default_locale as string
  }
  return "en"
}

const getProjectShortId = (storyVersion: StoryVersion) => {
  if (storyVersion.data.project.short_id) {
    return storyVersion.data.project.short_id as string
  }
  return "default"
}

onMounted(async () => {
  document.body.style.overflow = "scroll"

  const storyVersion = await getStoryVersion()
  if (storyVersion) {
    await store.setStoryVersion(storyVersion)

    const sceneId = getSceneId(storyVersion)
    const defaultLocale = getDefaultLocale(storyVersion)
    const projectShortId = getProjectShortId(storyVersion)

    if (sceneId) {
      router.push({
        name: "scene",
        params: {
          locale: defaultLocale,
          projectShortId,
          storyVersionId: storyVersionPublicId.value,
          sceneId,
        },
      })
    } else {
      throw new Error("Missing sceneId")
    }
  } else {
    throw new Error("Missing storyVersion")
  }
})
</script>
