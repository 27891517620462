import { createApp } from "vue"
import { createPinia } from "pinia"
import { createHead } from "@vueuse/head"
import { errorHandler } from "@appsignal/vue"
import { RouteLocationNormalized } from "vue-router"

import { appsignal } from "./appsignal"
import router from "./router"
import App from "./App.vue"
import { AppError } from "./errors/app_error"
import {
  checkErrorMessageLocalizations,
  localizeErrorMessage,
} from "./errors/messages"
import { get } from "@/api/main"

import {
  ProjectConfig,
  Scanpoint,
  projectConfigSchema,
  storyVersionSchema,
} from "./api/types"

import projects from "@/config/projects"
import "./assets/global.css"
import { LocalStorageError } from "./errors/jua"

const head = createHead()
const app = createApp(App)

const handleAppError = (err, instance, info) => {
  const locale = store.getCurrentLocale || "de"
  console.error(err, instance, info)
  // this will check if the error is a "known" error and display this
  if (
    import.meta.env.MODE !== "systemtestlocal" &&
    err instanceof AppError &&
    err?.silent !== true
  ) {
    alert(localizeErrorMessage(err.name, locale))
    return // return early to avoid calling errorHandler
  } else if (import.meta.env.MODE === "development") {
    if (err?.silent === true) {
      console.log("silenced an alert for error", err.name)
    } else {
      alert(err.name)
    }
  }
}

app.config.errorHandler = (err, instance, info) => {
  handleAppError(err, instance, info)
  errorHandler(appsignal, app)(err, instance, info)

  if (err?.message?.includes("Failed to fetch dynamically imported module")) {
    if (window?.location && router?.currentRoute?.value?.fullPath) {
      window.location = router?.currentRoute?.value?.fullPath
    }
  }
}

app.use(createPinia())
app.use(router)
app.use(head)

const store = useStore()

const projectShortIds = Object.keys(projects)

router.isReady().then(async () => {
  app.mount("#app")
})

const setUpProjectCSS = async (to: RouteLocationNormalized) => {
  let projectShortId = to?.params?.projectShortId
  if (projectShortIds.includes(projectShortId as string)) {
    const styles = import.meta.glob(`./assets/projects/**/*/main.css`)
    Object.entries(styles).forEach(([path, style]) => {
      if (path?.includes(projectShortId as string)) {
        style()
      }
    })
  } else {
    await import(`./assets/projects/default/main.css`)
  }
}

const setUpProjectDocument = async (projectConfig: ProjectConfig) => {
  const DEFAULT_TITLE = "brd.ge"

  if (import.meta.env.MODE === "development") {
    checkErrorMessageLocalizations()
  }

  // Set theme color
  if (projectConfig?.primaryColor) {
    const meta = document.createElement("meta")
    meta.name = "theme-color"
    meta.content = projectConfig.primaryColor
    document.getElementsByTagName("head")[0].appendChild(meta)
  }

  // Set title
  document.title = projectConfig?.config.publicTitle || DEFAULT_TITLE
}

router.beforeEach(async (to, from, next) => {
  const wg2Store = useWg2Store()

  const isLocalStorageAvailable = (): boolean => {
    const key = "TestBrdgeLocalStorage"
    const storage = window.localStorage
    try {
      storage.setItem(key, "works")
      const it = storage.getItem(key)
      if (it !== "works") {
        return false
      }
      storage.removeItem(key)
      return true
    } catch (error) {
      return false
    }
  }

  if (!isLocalStorageAvailable()) {
    throw new LocalStorageError()
  }

  try {
    if (
      !wg2Store?.getUser?.public_id ||
      wg2Store?.getUser?.public_id === "" ||
      (wg2Store?.getUser?.public_id &&
        wg2Store?.getUser?.public_id?.length !== 40)
    ) {
      next()
      return
    }
  } catch (err) {
    handleAppError(err, undefined, "")
    appsignal.sendError(err, (span) => {
      span.setTags({
        userPublicId: wg2Store?.getUser?.public_id || "N/A",
        journeyPublicId: wg2Store?.getJourney?.public_id || "N/A",
      })
    })
  }

  next()
})

router.beforeEach(async (to, from, next) => {
  if (["mock_channel_installation", "mock_channel_client"].includes(to?.name)) {
    next()
    return
  }

  const currentStoryVersionPublicId = store.getCurrentStoryVersionPublicId
  const locale =
    (to?.params?.locale as string) || (from?.params?.locale as string) || "de"
  const setLocale = (to?.query?.setLocale as string) || undefined
  const storyVersionId =
    (to?.params?.storyVersionPublicId as string) ||
    (from?.params?.storyVersionPublicId as string) ||
    undefined
  const scanpointPublicId =
    (to?.params?.scanpointPublicId as string) ||
    (from?.params?.scanpointPublicId as string) ||
    undefined
  const projectShortId =
    (to?.params?.projectShortId as string) ||
    (from?.params?.projectShortId as string) ||
    undefined
  const skip = from?.name === undefined && to?.name === "preview_entrypoint"

  if (setLocale !== undefined) {
    store.setCurrentLocale(setLocale)
  }
  const currentLocale = store.getCurrentLocale
  if (locale !== currentLocale && to.params.locale) {
    const localizedTo = to
    to.params.locale = currentLocale
    next(localizedTo)
    return
  }

  await setUpProjectCSS(to)

  let storyVersion = await store.getStoryVersion(storyVersionId)

  if (["wg2_index", "wg2_shared_journey"].includes(to?.name)) {
    const publishedStoryVersion = await get.publishedStoryVersion("wg2")
    // re-fetch story version and save to store
    const storyVersionN = await get.storyVersion(
      publishedStoryVersion.public_id
    )
    if (storyVersionN) {
      await store.setStoryVersion(storyVersionN)
      storyVersion = await store.getStoryVersion(
        publishedStoryVersion.public_id
      )
    }
  } else if (storyVersion === undefined && storyVersionId !== undefined) {
    // re-fetch story version and save to store
    const storyVersionN = await get.storyVersion(storyVersionId)
    if (storyVersionN) {
      await store.setStoryVersion(storyVersionN)
      storyVersion = await store.getStoryVersion(storyVersionId)
    }
  } else if (scanpointPublicId !== undefined) {
    if (currentStoryVersionPublicId === undefined) {
      const scanpoint = await get.scanpoint(scanpointPublicId)
      console.log("coming from scanpoint, need to refetch story version")
      console.log("scanpoint", scanpoint)
      await store.setStoryVersion(scanpoint.published_story_version)
    } else {
      console.log("coming from scanpoint, already found story version")
      storyVersion = await store.getStoryVersion(currentStoryVersionPublicId)
    }
  }

  if (projectShortId !== undefined) {
    const projectConfig = await projectConfigSchema.validate({
      short_id: projectShortId,
      config: projects[projectShortId],
    })
    await setUpProjectDocument(projectConfig)
  }

  let beforeHookNext = undefined
  if (storyVersion) {
    const rawStoryVersion = await storyVersionSchema.validate(storyVersion)
    const projectShortId = rawStoryVersion.data.project.short_id
    const projectConfig = await projectConfigSchema.validate({
      short_id: projectShortId,
      config: projects[projectShortId],
    })
    if (rawStoryVersion && !skip) {
      const runProjectBeforeHook = projectConfig.config.beforeHook
      beforeHookNext = await runProjectBeforeHook(
        to,
        from,
        next,
        projectConfig,
        rawStoryVersion
      )
    }
  } else {
    // START of workaround
    // this is a workaround for the wg2 "legacy interactive elements"
    // users access this QR code directly with an empty cache
    // because the cache is empty, we need to create a journey and redirect to onboarding
    if (to.name === "wg2_i_frame") {
      if (to.params?.slug === "xmas") {
        // disregard the xmas iframe, this is for passengers outside the museum
        // therefore, we don't need to show the onboarding!
      } else {
        const store = useWg2Store()
        const gstore = useStore()
        const getScanpoint = async () => {
          const scanpoint = await get.scanpoint("wow_sp55")
          if (scanpoint.published_story_version.data.story.scenes?.length) {
            return scanpoint
          }
        }
        const getStoryVersionFromScanpoint = async (scanpoint: Scanpoint) => {
          if (scanpoint.published_story_version.data.story.scenes?.length) {
            return scanpoint.published_story_version
          }
        }
        const scanpoint = await getScanpoint()
        const storyVersion = await getStoryVersionFromScanpoint(scanpoint)
        await gstore.setStoryVersion(storyVersion)
        // if there is no journey and user in the store, we create them
        if (
          store?.getJourney?.public_id === undefined ||
          store?.getUser?.public_id === undefined
        ) {
          // console.log("Journey and user do not exist...")
          // console.log("initializeAnonymousJuaUserWithJourney", storyVersionPublicId)
          await store.initializeAnonymousJuaUserWithJourney(
            storyVersion?.public_id
          )
        }
        // If the onboarding has not been completed, let's redirect to the onboarding
        if (
          store?.getOnboardingCompleted !== true &&
          to.name !== "wg2_onboarding" &&
          to.name !== "wg2_shared_journey"
        ) {
          router.push({
            name: "wg2_onboarding",
            params: {
              projectShortId: "wg2",
              locale: "de",
            },
          })
        }
      }
      // END of workaround
    }
  }

  if (beforeHookNext === undefined) {
    next()
  } else {
    next(beforeHookNext)
  }
})
