const MessageNamesArray = [
  // QR
  "App.QR.NotAllowedError",
  "App.QR.NotFoundError",
  "App.QR.NotSupportedError",
  "App.QR.NotReadableError",
  "App.QR.OverconstrainedError",
  "App.QR.StreamApiNotSupportedError",
  // UserCapture
  "App.UserCapture.UploadError",
  "App.UserCapture.SelectionError",
  "App.UserCapture.NoJourneyError",
  // JUA
  "App.JUA.LocalStorageError",
] as const
const GenericMessageNamesArray = ["AnErrorOccurred"] as const
const LocalesArray = ["de", "en", "it", "fr"] as const

type MessageName = (typeof MessageNamesArray)[number]
type GenericMessageName = (typeof GenericMessageNamesArray)[number]
type Locale = (typeof LocalesArray)[number]
type Messages = Record<MessageName, string>
type GenericMessages = Record<GenericMessageName, string>
type LocalizedMessages = Required<Record<Locale, Messages>>
type LocalizedGenericMessages = Required<Record<Locale, GenericMessages>>

const genericMessages: LocalizedGenericMessages = {
  de: {
    AnErrorOccurred: "Es ist ein Fehler aufgetreten",
  },
  en: {
    AnErrorOccurred: "An error occurred",
  },
  it: {
    AnErrorOccurred: "Si è verificato un errore",
  },
  fr: {
    AnErrorOccurred: "Une erreur s'est produite",
  },
}

const messages: LocalizedMessages = {
  de: {
    // QR
    "App.QR.NotAllowedError":
      "Der Benutzer hat die Berechtigung zum Zugriff auf die Kamera verweigert.",
    "App.QR.NotFoundError": "Es ist keine geeignete Kamera installiert.",
    "App.QR.NotSupportedError":
      "Die Seite wird nicht über HTTPS bereitgestellt.",
    "App.QR.NotReadableError":
      "Die Kamera ist nicht lesbar, möglicherweise wird sie bereits verwendet.",
    "App.QR.OverconstrainedError": "Die angeforderte Kamera existiert nicht.",
    "App.QR.StreamApiNotSupportedError":
      "Der Browser unterstützt diese Funktion nicht.",
    // UserCapture
    "App.UserCapture.UploadError":
      "Das aufgenommene Bild konnte nicht hochgeladen werden.",
    "App.UserCapture.SelectionError":
      "Das ausgewählte Bild konnte nicht verarbeitet werden.",
    "App.UserCapture.NoJourneyError":
      "Es ist keine Journey und/oder kein Benutzer mit dieser Anfrage verknüpft.",
    // JUA
    "App.JUA.LocalStorageError":
      "In deinem Browser ist LocalStorage deaktiviert. Bitte aktiviere LocalStorage, um die Inhalte zu laden.",
  },
  en: {
    // QR
    "App.QR.NotAllowedError":
      "The user denied the permission for camera access.",
    "App.QR.NotFoundError": "There is no suitable camera device installed.",
    "App.QR.NotSupportedError": "The page is not served over HTTPS.",
    "App.QR.NotReadableError":
      "The camera is not readable, maybe it is already in use.",
    "App.QR.OverconstrainedError": "The requested camera does not exist.",
    "App.QR.StreamApiNotSupportedError":
      "The browser does not support this feature.",
    // UserCapture
    "App.UserCapture.UploadError": "The captured image could not be uploaded.",
    "App.UserCapture.SelectionError":
      "The selected image could not be processed.",
    "App.UserCapture.NoJourneyError":
      "There is no journey and/or user associated with this request.",
    // JUA
    "App.JUA.LocalStorageError":
      "LocalStorage is disabled in your browser. Please enable LocalStorage to load the content.",
  },
  it: {
    // QR
    "App.QR.NotAllowedError":
      "L'utente ha negato il permesso per l'accesso alla fotocamera.",
    "App.QR.NotFoundError": "Non è installata una fotocamera adatta.",
    "App.QR.NotSupportedError": "La pagina non è servita su HTTPS.",
    "App.QR.NotReadableError":
      "La fotocamera non è leggibile, potrebbe essere già in uso.",
    "App.QR.OverconstrainedError": "La fotocamera richiesta non esiste.",
    "App.QR.StreamApiNotSupportedError":
      "Il browser non supporta questa funzione.",
    // UserCapture
    "App.UserCapture.UploadError":
      "L'immagine catturata non può essere caricata.",
    "App.UserCapture.SelectionError":
      "L'immagine selezionata non può essere elaborata.",
    "App.UserCapture.NoJourneyError":
      "Non esiste un 'journey' e/o utente associato a questa richiesta.",
    // JUA
    "App.JUA.LocalStorageError":
      "LocalStorage è disabilitato nel tuo browser. Abilita LocalStorage per caricare i contenuti.",
  },
  fr: {
    // QR
    "App.QR.NotAllowedError":
      "L'utilisateur a refusé l'autorisation d'accéder à la caméra.",
    "App.QR.NotFoundError": "Il n'y a pas de caméra appropriée installée.",
    "App.QR.NotSupportedError": "La page n'est pas servie via HTTPS.",
    "App.QR.NotReadableError":
      "La caméra n'est pas lisible, peut-être est-elle déjà utilisée.",
    "App.QR.OverconstrainedError": "La caméra demandée n'existe pas.",
    "App.QR.StreamApiNotSupportedError":
      "Le navigateur ne prend pas en charge cette fonctionnalité.",
    // UserCapture
    "App.UserCapture.UploadError":
      "L'image capturée n'a pas pu être téléchargée.",
    "App.UserCapture.SelectionError":
      "L'image sélectionnée n'a pas pu être traitée.",
    "App.UserCapture.NoJourneyError":
      "Il n'y a pas de 'journey' et/ou d'utilisateur associé à cette demande.",
    // JUA
    "App.JUA.LocalStorageError":
      "LocalStorage est désactivé dans votre navigateur. Veuillez activer LocalStorage pour charger le contenu.",
  },
}

const formatMessage = (message: string, locale: Locale) => {
  return `${genericMessages[locale].AnErrorOccurred}: ${message}`
}

export const localizeErrorMessage = (
  name: MessageName,
  locale: Locale
): string | undefined => {
  const messageLocale = messages[locale]
  if (messageLocale && messageLocale[name]) {
    return formatMessage(messageLocale[name], locale)
  } else {
    console.error(`Missing translation for key '${name}' in locale '${locale}'`)
  }
}

export const checkErrorMessageLocalizations = () => {
  LocalesArray.forEach((locale) => {
    const localeMessages = messages[locale]
    if (!localeMessages) {
      console.error(`Missing locale key '${locale}'`)
      return
    }
    MessageNamesArray.forEach((name) => {
      if (!(name in localeMessages)) {
        console.error(
          `Missing translation for key '${name}' in locale '${locale}'`
        )
      }
    })
  })
}
