<template>
  <Transition name="nested1" :duration="{ enter: 2200, leave: 900 }" appear>
    <div
      v-if="installationControlsViewShown"
      class="left-0 top-0 right-0 bottom-0 z-10 flex flex-col h-full"
    >
      <div
        class="absolute left-0 top-0 right-0 bottom-0 z-50 bg-black bg-opacity-75"
        id="backdrop"
        @click="hide"
      ></div>

      <div class="absolute z-40 w-full h-full">
        <div class="halfrounded h-full"></div>
      </div>

      <div
        class="absolute transform transition-all duration-700 z-50 w-full h-64"
        ref="controls"
        id="controls-outer"
      >
        <div class="w-full h-full bg-wg2-red flex flex-col flex-grow">
          <Transition name="fade" mode="out-in">
            <div
              class="px-6 py-6 w-full h-full flex flex-col justify-end flex-grow"
              id="label"
              v-if="!showBlocker && !showUploadSpinner && !isRetake"
            >
              <div class="text-2xl text-white pb-2 font-bold uppercase">
                {{ t("photo_station.remote_control") }}
              </div>
              <div class="text-lg text-white pb-6">
                {{ timeRemainingString }}
              </div>
              <div class="flex gap-x-4" v-if="sessionActive">
                <button
                  v-if="showCycleButton"
                  class="control-button"
                  @click="cycle"
                >
                  {{ t("photo_station.change_effect") }}
                </button>
                <button class="control-button" @click="startCapture('photo')">
                  {{ t("photo_station.photo") }}
                </button>
              </div>
            </div>
            <div
              class="w-full h-full flex flex-col justify-end flex-grow"
              id="label"
              v-else-if="!showBlocker && !showUploadSpinner && isRetake"
            >
              <div class="w-full mb-8 max-h-60">
                <img
                  class="h-full object-center object-contain mx-auto"
                  :src="present?.url"
                />
              </div>
              <div class="px-6 pb-6 text-lg text-white">
                {{ timeRemainingRetakeString }}
              </div>
              <div class="px-6 pb-6 flex gap-x-4">
                <button
                  v-if="showCycleButton"
                  class="control-button"
                  @click="cycle"
                >
                  {{ t("photo_station.change_effect") }}
                </button>
                <button class="control-button" @click="startCapture('photo')">
                  {{ t("photo_station.photo") }}
                </button>
              </div>
            </div>
            <div
              v-else-if="showUploadSpinner"
              class="px-6 pb-6 w-full h-full flex flex-col items-center justify-center flex-grow"
            >
              <div
                class="w-40 h-40 flex items-center justify-center overflow-hidden"
              >
                <Vue3Lottie :animationData="WhiteSpinnerJSON" />
              </div>
            </div>
            <div
              v-else
              class="px-6 pb-6 w-full h-full flex flex-col items-center justify-center flex-grow"
            >
              <div
                class="w-40 h-40 flex items-center justify-center overflow-hidden"
              >
                <Vue3Lottie style="scale: 300%" :animationData="SelfieJSON" />
              </div>

              <div class="text-3xl font-bold text-white zoom-text">
                {{ t("photo_station.pose") }}
              </div>
            </div>
          </Transition>
        </div>

        <div class="relative" v-if="showCloseButton">
          <div class="absolute w-full z-20 flex justify-center items-end pt-3">
            <div
              @click="hide()"
              id="close-button"
              class="rounded-full bg-wg2-red h-14 w-14 text-white flex justify-center items-center transition-all active:scale-125"
            >
              <div
                class="w-12 h-12 border-2 border-white bg-wg2-red flex justify-center rounded-full text-4xl items-center"
              >
                <span class="material-symbols-rounded">close</span>
              </div>
            </div>
          </div>
          <div class="text-wg2-red h-11 -mt-1">
            <svg
              class="w-full h-full absolute"
              viewBox="0 0 360 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
            >
              <path
                d="M361 1.1225C307.27 19.5616 246.009 30 181 30C115.991 30 54.7304 19.5616 1 1.1225V1H181H361V1.1225Z"
                fill="currentColor"
              />
              <path
                d="M1 1V1.1225C54.7304 19.5616 115.991 30 181 30C246.009 30 307.27 19.5616 361 1.1225V1"
                stroke="white"
                stroke-width="3px"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import { Vue3Lottie } from "vue3-lottie"
import "vue3-lottie/dist/style.css"
import SelfieJSON from "@/assets/projects/wg2/animations/selfie.json"
import WhiteSpinnerJSON from "@/assets/projects/wg2/animations/white-spinner.json"

import { ClientChannel } from "@/channels/client_channel"
import { setupClientChannel, cleanClientChannel } from "@/channels/client"
import { PresentMessage, StartSessionMessage } from "@/channels/types"

import { t } from "@/helpers/i18n"

const route = useRoute()
const router = useRouter()
const store = useWg2Store()

const controls = ref(null)
const showCycleButton = ref(true)

const present = ref<PresentMessage>()

const showCloseButton = ref(true)
const showBlocker = ref(false)
const showUploadSpinner = ref(false)
const isRetake = ref(false)

const props = defineProps<{
  installationControlsViewShown: boolean
}>()

const emit = defineEmits(["hide"])
// const props = defineProps(["show"])

const hide = async () => {
  await manuallyDisconnect()
  window.setTimeout(() => emit("hide"))
  document.body.style.overflow = "scroll"
}

watch(
  () => props.installationControlsViewShown,
  (n, o) => {
    if (n === true) {
      window.scrollTo({ top: 0, behavior: "smooth" })
      document.body.style.overflow = "hidden"
    }
  }
)

const resetTime = async () => {
  if (timeoutInterval.value) {
    clearInterval(timeoutInterval.value)
  }
  timeRemaining.value = timeout
  timeoutInterval.value = window.setInterval(() => {
    timeRemaining.value -= 1
    if (timeRemaining.value < 0) {
      timeRemaining.value = 0
      stopSession()
    }
  }, 1000)
}

const timeout = 30

const channel = ref<ClientChannel>()
const timeoutInterval = ref()
const timeRemaining = ref(timeout)
const sessionActive = computed(() => timeRemaining.value > 0)
const stoppedSession = ref(false)

const timeRemainingString = computed(() =>
  t("photo_station.time_remaining")
    .toString()
    .replace("{seconds}", timeRemaining.value.toString())
)

const timeRemainingRetakeString = computed(() =>
  t("photo_station.time_remaining_retake")
    .toString()
    .replace("{seconds}", timeRemaining.value.toString())
)

onMounted(async () => {
  // f.value = await l("qr_code_reader.go_and_find")
  await setupChannelAndStartSession()

  channel.value?.on("disconnect", async () => {
    await stopSession()
  })
  channel.value?.on("stop_session", async () => {
    await stopSession()
  })
  channel.value?.on("upload", async (message) => {
    await transitionToUploadSpinner()
  })
  channel.value?.on("present", async (message) => {
    present.value = message
    await transitionToPresent()
  })
})

const setupChannelAndStartSession = async () => {
  const rawSessionHash = route?.query?.h as string
  const sessionHash = rawSessionHash?.substring(0, 24)
  const kind = rawSessionHash?.slice(-1) || ""
  if (kind === "3") {
    showCycleButton.value = false
  } else {
    showCycleButton.value = true
  }

  const installationPublicId: string | undefined =
    sessionHash?.substring(0, 8) || undefined
  if (
    installationPublicId === "wow_you2" &&
    store.getUser?.anycable_jwt_token &&
    sessionHash?.length === 24 &&
    rawSessionHash?.length === 25 &&
    rawSessionHash?.startsWith("wow_you2")
  ) {
    channel.value = await setupClientChannel(
      installationPublicId,
      store.getUser?.anycable_jwt_token,
      sessionHash
    )
    try {
      const message: StartSessionMessage = {
        jua_user_public_id: store.getUser?.public_id,
        kind,
      }
      await channel.value.start_session(message)
      await resetTime()
    } catch (error) {
      console.error("An error occurred:", error)
      stopSession()
    }
  }
}

const manuallyDisconnect = async () => {
  channel.value?.disconnect()
  await stopSession()
}

const extendSession = async () => {
  await channel.value?.extend_session({})
  await resetTime()
}

const stopSession = async () => {
  if (timeoutInterval.value) {
    clearInterval(timeoutInterval.value)
  }
  console.log("stopSession")
  stoppedSession.value = true
  emit("hide")
  document.body.style.overflow = "scroll"
  cleanClientChannel()
}

// onBeforeUnmount(() => {
//   cleanClientChannel()
// })

const startCapture = async (type: string) => {
  await extendSession()
  try {
    await channel.value?.capture({ type })
    await transitionToBlocker()
  } catch (error) {
    console.error("An error occurred:", error)
    alert("An error occurred! Check console for details.")
  }
}

const cycle = async () => {
  await extendSession()
  try {
    await channel.value?.cycle({})
  } catch (error) {
    console.error("An error occurred:", error)
    alert("An error occurred! Check console for details.")
  }
}

const transitionToBlocker = async () => {
  await extendSession()

  showBlocker.value = true
  if (controls.value?.style) {
    controls.value.style.height = "100vh"
  }
}

const transitionToUploadSpinner = async () => {
  await extendSession()

  showBlocker.value = false
  showUploadSpinner.value = true
}

const transitionToPresent = async () => {
  await extendSession()

  showCloseButton.value = true
  showUploadSpinner.value = false
  isRetake.value = true

  if (controls.value?.style) {
    controls.value.style.height = ""
    controls.value.classList.add("h-show")
    controls.value.classList.remove("h-64")
  }
}
</script>

<style scoped lang="postcss">
.h-show {
  height: 28rem;
}
.control-button {
  @apply text-wg2-red font-bold bg-white px-4 py-1 inline-block rounded-full  transition-all active:scale-125;
}

.nested1-enter-active #backdrop,
.nested1-leave-active #backdrop {
  transition: opacity 0.3s ease-in-out;
}
.nested1-enter-active #backdrop {
  transition-delay: 0;
}
.nested1-enter-from #backdrop,
.nested1-leave-to #backdrop {
  opacity: 0.001;
}

.nested1-enter-active #controls-outer,
.nested1-leave-active #controls-outer {
  transition: all 0.3s ease-in-out;
}
.nested1-enter-active #controls-outer {
  transition-delay: 0.3s;
}
.nested1-enter-from #controls-outer,
.nested1-leave-to #controls-outer {
  transform: translateY(-100vh);

  opacity: 0.001;
}
/*
.nested1-enter-active #label,
.nested1-leave-active #label {
  transition: opacity 0.3s ease-in-out;
}
.nested1-enter-active #label {
  transition-delay: 0.1s;
}
.nested1-enter-from #label,
.nested1-leave-to #label {
  opacity: 0.001;
} */

.nested1-enter-active #close-button,
.nested1-leave-active #close-button {
}
.nested1-enter-active #close-button {
}
.nested1-enter-from #close-button,
.nested1-leave-to #close-button {
}

.zoom-text {
  animation: zoom infinite 2s ease-in-out;
  animation-delay: 0.5s;
}
@keyframes zoom {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
