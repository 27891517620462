import { AppError } from "./app_error"

class JUAError extends AppError {
  constructor(silent: boolean = false) {
    super(silent)
    this.name += "JUA."
  }
}

export class LocalStorageError extends JUAError {
  constructor() {
    super(true)
    this.name += "LocalStorageError"
  }
}
