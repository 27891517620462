<template>
  <div class="p-4 flex justify-center" v-if="showFallback">
    <div class="text-center">
      Welcome to the
      <a class="font-bold underline" target="_blank" href="https://api.brd.ge/"
        >brd.ge</a
      >
      Platform
      <div class="text-gray-500">
        &copy; 2022-{{ year }}
        <a target="_blank" href="https://naut.ch/">–naut GmbH</a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const year = new Date().getFullYear()

const showFallback = ref(false)
const router = useRouter()

onMounted(async () => {
  document.body.style.overflow = "scroll"

  const hostname = location.hostname

  if (
    hostname.includes("wow.brd.ge") ||
    // [TODO] Remove this
    hostname.includes("f.brd.ge")
  ) {
    router.push({
      name: "wg2_index",
      params: { locale: "de", projectShortId: "wg2" },
    })
  } else {
    showFallback.value = true
  }
})
// import { ref, onMounted } from "vue"
// import { get } from "@/api/main"

// import { post } from "@/api/main"

// const activity = ref()

// onMounted(async () => {
//   const journey = await post.journey("184005509", "814167031273684993")
//   const user = await post.user(journey.id, "foo_bar123@gmail.com")
//   activity.value = await post.activity(user.id, { foo: "lmao" })
// })

// const ba = ref()

// onMounted(async () => {
//   const a = await get.storyVersion("d9e5exvi")
//   const b = a.data.story.locales
//   console.log(a, b)
//   ba.value = b
// })
</script>
