import { Channel, ChannelEvents } from "@anycable/web"
import {
  StartSessionMessage,
  ExtendSessionMessage,
  StopSessionMessage,
  CaptureMessage,
  ConfirmCaptureMessage,
  ConfirmPresentMessage,
  ConfirmStartSessionMessage,
  ConfirmSelectMessage,
  PresentMessage,
  DisconnectMessage,
  ActionMessage,
  UploadMessage,
  ConfirmUploadMessage,
  ConfirmCycleMessage,
  CycleMessage,
  SelectMessage,
  ReadyMessage,
  ConfirmReadyMessage,
  IdleMessage,
  ConfirmIdleMessage,
} from "./types"

type Params = {
  installation_public_id: string
}

interface Events extends ChannelEvents<ActionMessage> {
  message: (message: any) => void
  connect: (message: any) => void
  disconnect: (message: DisconnectMessage) => void
  close: (message: any) => void
  // incoming
  stop_session: (message: StopSessionMessage) => void
  confirm_start_session: (message: ConfirmStartSessionMessage) => void
  confirm_select: (message: ConfirmSelectMessage) => void
  confirm_capture: (message: ConfirmCaptureMessage) => void
  confirm_cycle: (message: ConfirmCycleMessage) => void
  upload: (message: UploadMessage) => void
  ready: (message: ReadyMessage) => void
  idle: (message: IdleMessage) => void
  present: (message: PresentMessage) => void
  // outgoing
  start_session: (message: StartSessionMessage) => void
  select: (message: SelectMessage) => void
  capture: (message: CaptureMessage) => void
  cycle: (message: CycleMessage) => void
  extend_session: (message: ExtendSessionMessage) => void
  confirm_upload: (message: ConfirmUploadMessage) => void
  confirm_ready: (message: ConfirmReadyMessage) => void
  confirm_idle: (message: ConfirmIdleMessage) => void
  confirm_present: (message: ConfirmPresentMessage) => void
}

export class ClientChannel extends Channel<Params, ActionMessage, Events> {
  static identifier = "ClientChannel"

  async start_session(message: StartSessionMessage) {
    this.emit("start_session", message)
    return this.perform("start_session", message)
  }

  async select(message: SelectMessage) {
    this.emit("select", message)
    return this.perform("select", message)
  }

  async capture(message: CaptureMessage) {
    this.emit("capture", message)
    return this.perform("capture", message)
  }

  async cycle(message: CycleMessage) {
    this.emit("cycle", message)
    return this.perform("cycle", message)
  }

  async extend_session(message: ExtendSessionMessage) {
    this.emit("extend_session", message)
    return this.perform("extend_session", message)
  }

  receive(message: ActionMessage) {
    if (message.action === "stop_session") {
      this.emit("stop_session", message)
    } else if (message.action === "confirm_start_session") {
      this.emit("confirm_start_session", message as ConfirmStartSessionMessage)
    } else if (message.action === "confirm_select") {
      this.emit("confirm_select", message as ConfirmSelectMessage)
    } else if (message.action === "confirm_capture") {
      this.emit("confirm_capture", message as ConfirmCaptureMessage)
    } else if (message.action === "confirm_cycle") {
      this.emit("confirm_cycle", message as ConfirmCycleMessage)
    } else if (message.action === "upload") {
      this.emit("upload", message as UploadMessage)
      this.perform("confirm_upload", message)
      this.emit("confirm_upload", message as ConfirmUploadMessage)
    } else if (message.action === "ready") {
      this.emit("ready", message as ReadyMessage)
      this.perform("confirm_ready", message)
      this.emit("confirm_ready", message as ConfirmReadyMessage)
    } else if (message.action === "idle") {
      this.emit("idle", message as IdleMessage)
      this.perform("confirm_idle", message)
      this.emit("confirm_idle", message as ConfirmIdleMessage)
    } else if (message.action === "present") {
      this.emit("present", message as PresentMessage)
      this.perform("confirm_present", message)
      this.emit("confirm_present", message as ConfirmPresentMessage)
    }

    super.receive(message)
  }
}
