import { createRouter, createWebHistory } from "vue-router"

import Index from "@/pages/index.vue"
import Settings from "@/pages/settings.vue"
import BugReport from "@/pages/bug_report.vue"
import StoryVersionEntrypoint from "@/pages/story_version_entrypoint.vue"
import PreviewEntrypoint from "@/pages/preview_entrypoint.vue"
import ScanpointEntrypoint from "@/pages/scanpoint_entrypoint.vue"
import InstallationEntrypoint from "@/pages/installation_entrypoint.vue"
import Scene from "@/pages/scene.vue"

// import PhotoDemo from "@/pages/photo_demo.vue"
// import PhotoDemoStatic from "@/pages/photo_demo_static.vue"

import Wg2Help from "@/pages/projects/wg2/help.vue"
import Wg2Journey from "@/pages/projects/wg2/journey.vue"
import Wg2SharedJourney from "@/pages/projects/wg2/shared_journey.vue"
import Wg2Activity from "@/pages/projects/wg2/activity.vue"
import Wg2Onboarding from "@/pages/projects/wg2/onboarding.vue"
import Wg2IFrame from "@/pages/projects/wg2/i/frame.vue"
import Wg2Index from "@/pages/projects/wg2/index.vue"

import MockChannelInstallation from "@/pages/mock/channel/installation.vue"
import MockChannelClient from "@/pages/mock/channel/client.vue"

const routes = [
  {
    path: "/",
    name: "index",
    component: Index,
    meta: {
      title: "Index",
    },
  },
  {
    path: "/settings/",
    name: "settings",
    component: Settings,
    meta: {
      title: "Settings",
    },
  },
  {
    path: "/bug_report/:bugReportQrCode/:internalQrCodeScanner?",
    name: "bug_report",
    component: BugReport,
    meta: {
      title: "BugReport",
    },
  },
  {
    path: "/e/:storyVersionPublicId/",
    name: "story_version_entrypoint",
    component: StoryVersionEntrypoint,
    props: true,
  },
  {
    path: "/s/:scanpointPublicId/",
    name: "scanpoint_entrypoint",
    component: ScanpointEntrypoint,
    props: true,
  },
  {
    path: "/i/:sessionHash/",
    name: "installation_entrypoint",
    component: InstallationEntrypoint,
    props: true,
  },
  {
    path: "/p/:locale/:projectShortId/:storyVersionPublicId/:sceneId/",
    name: "preview_entrypoint",
    component: PreviewEntrypoint,
    props: true,
  },
  {
    path: "/:locale/:projectShortId/:storyVersionPublicId/:sceneId/",
    name: "scene",
    component: Scene,
    props: true,
  },
  {
    path: "/mock/channel/installation/:installation_public_id/:installation_token/",
    name: "mock_channel_installation",
    component: MockChannelInstallation,
    props: true,
  },
  {
    path: "/mock/channel/client/:installation_public_id/:jua_user_public_id/:jua_user_token/:session_hash/",
    name: "mock_channel_client",
    component: MockChannelClient,
    props: true,
  },
  // {
  //   path: "/photo_demo/",
  //   name: "photo_demo",
  //   component: PhotoDemo,
  //   props: true,
  // },
  // {
  //   path: "/photo_demo_static/",
  //   name: "photo_demo_static",
  //   component: PhotoDemoStatic,
  //   props: true,
  // },
  // ---
  {
    path: "/:locale/:projectShortId/help/",
    name: "wg2_help",
    component: Wg2Help,
    props: true,
  },
  {
    path: "/:locale/:projectShortId/journey/",
    name: "wg2_journey",
    component: Wg2Journey,
    props: true,
  },
  {
    path: "/:locale/:projectShortId/journey/shared/:journeyPublicId/",
    name: "wg2_shared_journey",
    component: Wg2SharedJourney,
    props: true,
  },
  {
    path: "/:locale/:projectShortId/activity/:publicId/",
    name: "wg2_activity",
    component: Wg2Activity,
    props: true,
  },
  {
    path: "/:locale/:projectShortId/onboarding/",
    name: "wg2_onboarding",
    component: Wg2Onboarding,
    props: true,
  },
  {
    path: "/:locale/:projectShortId/i/frame/:slug/",
    name: "wg2_i_frame",
    component: Wg2IFrame,
    props: true,
  },
  {
    path: "/:locale/:projectShortId/",
    name: "wg2_index",
    component: Wg2Index,
    props: true,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
