<template>
  <Layout
    :hide-actions="step <= steps"
    :hide-foo="true"
    @show-qr-code-reader="showQrCodeReader"
  >
    <div
      class="flex flex-col justify-end space-y-8 p-4 relative overflow-hidden"
      style="height: 35rem"
      ref="touchableElement"
    >
      <Transition name="slide-fade" mode="out-in">
        <div v-if="step === 1" class="flex flex-col justify-between h-full">
          <div class="flex gap-x-4 text-2xl">
            <div v-for="locale of locales" class="uppercase">
              <router-link
                :to="{
                  name: 'wg2_onboarding',
                  params: { locale, projectShortId: 'wg2' },
                  query: { setLocale: locale },
                }"
                class="font-bold"
                :class="route.params.locale === locale ? '' : 'text-wg2-red'"
              >
                {{ locale }}
              </router-link>
            </div>
          </div>

          <div>
            <h1 class="step-heading">
              {{ t("onboarding.steps.welcome.heading") }}
            </h1>
            <div class="step-body">
              {{ t("onboarding.steps.welcome.body") }}
            </div>
          </div>
        </div>
        <div v-else-if="step === 2" class="flex flex-col justify-end h-full">
          <h1 class="step-heading">
            {{ t("onboarding.steps.guide.heading") }}
          </h1>
          <div class="step-body">
            {{ t("onboarding.steps.guide.body") }}
          </div>
        </div>
        <div v-else-if="step === 3" class="flex flex-col justify-end h-full">
          <h1 class="step-heading">
            {{ t("onboarding.steps.share_journey_link.heading") }}
          </h1>
          <div class="step-body">
            {{ t("onboarding.steps.share_journey_link.body") }}
          </div>
          <div class="mt-4">
            <button class="navigation-button" @click="shareJourney">
              {{ t("journey.create_journey_link") }}
            </button>
          </div>
        </div>
        <div v-else-if="step === 4" class="flex flex-col justify-end h-full">
          <h1 class="step-heading">
            {{ t("onboarding.steps.camera.heading") }}
          </h1>
          <div class="step-body">
            {{ t("onboarding.steps.camera.body") }}
          </div>
        </div>
        <div v-else class="flex flex-col justify-end h-full">
          <h1 class="step-heading">
            {{ t("onboarding.steps.well_done.heading") }}
          </h1>
          <div class="step-body">
            {{ t("onboarding.steps.well_done.body") }}
          </div>
        </div>
      </Transition>

      <Transition name="fade" mode="out-in">
        <div v-if="step <= steps" class="h-40">
          <div class="flex justify-start gap-x-8 items-center">
            <div class="flex items-center">
              <button
                v-if="step <= steps"
                class="navigation-button"
                @click="forward"
              >
                <span>{{ t("shared.next") }}</span>
              </button>
              <!-- <div v-else class="flex items-center text-xl font-bold gap-x-4">
                <button class="navigation-button" @click="accept">
                  {{ t("shared.yes") }}
                </button>
                <button class="navigation-button" @click="deny">
                  {{ t("shared.no") }}
                </button>
              </div> -->
            </div>
            <div class="flex gap-x-2">
              <div
                v-for="i in steps"
                :key="i"
                @click="jump(i)"
                class="h-3 w-3 rounded-full"
                :class="
                  step === i ? 'bg-wg2-red' : 'bg-white border border-wg2-red'
                "
              />
            </div>
          </div>
          <div
            class="underline font-bold text-wg2-red mt-8 text-lg"
            @click="jump(steps)"
            v-if="step < steps"
          >
            {{ t("onboarding.actions.skip") }}
          </div>
        </div>
        <div v-else class="h-40"></div>
      </Transition>
    </div>
    <Transition name="fade" mode="out-in">
      <div
        v-if="step > steps && arrowShown"
        class="absolute bottom-28 w-full z-0 flex"
      >
        <svg
          class="arrow text-wg2-red transform rotate-12"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 43.1 85.9"
          style="enable-background: new 0 0 43.1 85.9"
          xml:space="preserve"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            class="st0 draw-arrow"
            d="M11.3,2.5c-5.8,5-8.7,12.7-9,20.3s2,15.1,5.3,22c6.7,14,18,25.8,31.7,33.1"
          />
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            class="draw-arrow tail-1"
            d="M40.6,78.1C39,71.3,37.2,64.6,35.2,58"
          />
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            class="draw-arrow tail-2"
            d="M39.8,78.5c-7.2,1.7-14.3,3.3-21.5,4.9"
          />
        </svg>
      </div>
    </Transition>
  </Layout>
</template>

<script setup lang="ts">
import Layout from "@/layouts/projects/wg2/default.vue"
import { ref } from "vue"
import { t } from "@/helpers/i18n"
import { post } from "@/api/main"

const store = useWg2Store()
const route = useRoute()
const router = useRouter()
const locales = ["de", "fr", "it", "en"]

const touchableElement = ref(null)
const touchstartX = ref(0)
const touchstartY = ref(0)
const touchendX = ref(0)
const touchendY = ref(0)

const step = ref(1)
const steps = 4

const arrowShown = ref(true)

onMounted(() => {
  document.body.style.overflow = "scroll"
  touchableElement.value.addEventListener(
    "touchstart",
    function (event: any) {
      touchstartX.value = event?.changedTouches[0]?.screenX
      touchstartY.value = event?.changedTouches[0]?.screenY
    },
    false
  )

  touchableElement.value.addEventListener(
    "touchend",
    function (event: any) {
      touchendX.value = event?.changedTouches[0]?.screenX
      touchendY.value = event?.changedTouches[0]?.screenY
      handleGesture()
    },
    false
  )
})

const onNavigate = () => {
  if (step.value === steps) {
    prompt()
  }
}

const forward = () => {
  onNavigate()
  if (step.value <= steps) {
    step.value += 1
  }
}

const backward = () => {
  onNavigate()
  if (step.value > 1) {
    step.value -= 1
  }
}

const jump = (i: number) => {
  step.value = i
  onNavigate()
}

// const accept = async () => {
//   const publicId = store.getUser?.public_id
//   if (publicId) {
//     await post.activity(publicId, "wg2_photo_contest", {
//       accepted: true,
//     })
//   }
//   step.value += 1
//   onNavigate()
// }

// const deny = async () => {
//   const publicId = store.getUser?.public_id
//   if (publicId) {
//     await post.activity(publicId, "wg2_photo_contest", {
//       accepted: false,
//     })
//   }
//   step.value += 1
//   onNavigate()
// }

const prompt = async () => {
  const stream = await navigator?.mediaDevices?.getUserMedia({
    audio: false,
    video: {
      facingMode: "environment",
    },
  })
  setTimeout(() => {
    // Stop all tracks of the stream
    stream.getTracks().forEach((track) => track.stop())
    // console.log("stopped all tracks for stream")
  }, 300)
}

const showQrCodeReader = () => {
  arrowShown.value = false
  store.setOnboardingCompleted()
}

function handleGesture() {
  if (touchendX.value < touchstartX.value) {
    // console.log("Swiped Left")
    forward()
  }
  if (touchendX.value > touchstartX.value) {
    // console.log("Swiped Right")
    backward()
  }

  // if (touchendY.value < touchstartY.value) {
  //   console.log("Swiped Up")
  // }
  // if (touchendY.value > touchstartY.value) {
  //   console.log("Swiped Down")
  // }
  // if (touchendY === touchstartY) {
  //   console.log("Tap")
  // }
}

const shareJourney = () => {
  const sharePath = router.resolve({
    name: "wg2_shared_journey",
    params: { journeyPublicId: store.journey.public_id },
  }).href
  const url = `${import.meta.env.VITE_FRONTEND_BASE}${sharePath}`
  console.log(url)
  if (navigator?.share) {
    navigator
      .share({
        url,
      })
      .then(() => {
        console.log("Thanks for sharing!")
      })
      .catch(console.error)
  } else {
    // fallback
  }
}
</script>

<style scoped lang="postcss">
.step-heading {
  @apply flex items-center font-bold text-3xl text-wg2-red uppercase mb-4;
}

.step-body {
  @apply text-lg;
}

.navigation-button {
  @apply bg-wg2-red px-5 py-1 text-lg rounded-full text-white font-bold;
}

.slide-fade-enter-active {
  transition: all 0.5s ease;
}

.slide-fade-leave-active {
  transition: all 0.5s ease;
}

.slide-fade-enter-from {
  transform: translateX(20px);
  opacity: 0;
}

.slide-fade-leave-to {
  transform: translateX(-20px);
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

svg.arrow {
  width: 200px;
  height: 200px;
  margin: 0;
  & .draw-arrow {
    stroke-width: 4px;
    stroke: currentColor;
    fill: none;
    stroke-dasharray: 400;
    stroke-dashoffset: 400;
    animation-delay: 1s;
    animation-duration: 5s;
    animation-fill-mode: forwards;
    animation-name: draw;
    &.tail-1 {
      animation-delay: 2.2s;
    }
    &.tail-2 {
      animation-delay: 2.7s;
    }
  }
}
@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}
</style>
