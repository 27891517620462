import { defineStore } from "pinia"
import { useStorage } from "@vueuse/core"
import { StoryVersion, StoryVersionsMap } from "../api/types"

const emptyStoryVersionsMap: StoryVersionsMap = {}

export const useStore = defineStore("main", {
  state: () => ({
    debug: import.meta.env.MODE === "development",
    isInitialized: false,
    currentStoryVersionPublicId: useStorage(
      "currentStoryVersionPublicId",
      undefined
    ),
    currentLocale: useStorage("currentLocale", "de"),
    storyVersions: useStorage("storyVersions", emptyStoryVersionsMap),
  }),

  actions: {
    initApp() {
      this.isInitialized = true
      console.log("App initialized!")
    },
    async setStoryVersion(storyVersion: StoryVersion) {
      this.currentStoryVersionPublicId = storyVersion.public_id
      this.storyVersions[storyVersion.public_id] = storyVersion
    },
    async setCurrentLocale(locale: string) {
      this.currentLocale = locale
    },
  },

  getters: {
    isReady: (state) => {
      return !state.isInitialized
    },
    getStoryVersion: (state) => {
      return async (storyVersionId: string) =>
        state.storyVersions[storyVersionId]
    },
    getCurrentStoryVersionPublicId: (state) => {
      return state.currentStoryVersionPublicId
    },
    getCurrentStoryVersion: (state) => {
      if (state.currentStoryVersionPublicId) {
        return state.storyVersions[state.currentStoryVersionPublicId]
      }
      return undefined
    },
    getCurrentLocale: (state) => {
      return state.currentLocale
    },
  },
})
