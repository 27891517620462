// import { useAuthStore } from "@/stores"

import ky from "ky"
import pTimeout from "p-timeout"

import {
  StoryVersion,
  storyVersionSchema,
  Journey,
  journeySchema,
  User,
  userSchema,
  Activity,
  activitySchema,
  scanpointSchema,
  Scanpoint,
  assetSchema,
  installationSchema,
  Installation,
  successSchema,
  BugReport,
  bugReportSchema,
} from "@/api/types"

const API_BASE = import.meta.env.VITE_API_BASE

export const waitFor = async (
  p: () => Promise<void>,
  timeout: number = 3000
): Promise<void> => {
  return await pTimeout(p(), {
    milliseconds: timeout,
  })
}

const genericOptions = {
  prefixUrl: API_BASE + "/api",
}

export const genericGetRequest = async (e: string, o: object = {}) =>
  await ky.get(e, { ...genericOptions, ...o }).json()

export const genericPostRequest = async (
  e: string,
  json: object = {},
  o: object = {}
) => await ky.post(e, { ...genericOptions, json, ...o }).json()

export const genericDeleteRequest = async (
  e: string,
  json: object = {},
  o: object = {}
) => await ky.delete(e, { ...genericOptions, json, ...o }).json()

export const genericFormDataPostRequest = async (
  e: string,
  formData: FormData
) => await ky.post(e, { ...genericOptions, body: formData }).json()

export const get = {
  publishedStoryVersion: async (
    projectShortId: string
  ): Promise<StoryVersion> => {
    return await storyVersionSchema.validate(
      await genericGetRequest(`story_versions/published/${projectShortId}`)
    )
  },
  storyVersion: async (storyVersionPublicId: string): Promise<StoryVersion> => {
    return await storyVersionSchema.validate(
      await genericGetRequest(`story_versions/${storyVersionPublicId}`)
    )
  },
  scanpoint: async (scanpointPublicId: string): Promise<Scanpoint> => {
    return await scanpointSchema.validate(
      await genericGetRequest(`scanpoints/${scanpointPublicId}`)
    )
  },
  installation: async (installationPublicId: string): Promise<Installation> => {
    return await installationSchema.validate(
      await genericGetRequest(`installations/${installationPublicId}`)
    )
  },
  user: async (juaUserPublicId: string): Promise<User> => {
    return await userSchema.validate(
      await genericGetRequest(`jua_users/${juaUserPublicId}`)
    )
  },
  sharedJourney: async (juaJourneyPublicId: string): Promise<Journey> => {
    return await journeySchema.validate(
      await genericGetRequest(`jua_journeys/shared/${juaJourneyPublicId}`)
    )
  },
}

export const post = {
  journey: async (storyVersionPublicId: string): Promise<Journey> => {
    return await journeySchema.validate(
      await genericPostRequest(`jua_journeys`, {
        story_version_public_id: storyVersionPublicId,
      })
    )
  },
  user: async (
    journeyPublicId: string,
    email: string | undefined = undefined
  ): Promise<User> => {
    return await userSchema.validate(
      await genericPostRequest(`jua_users`, {
        jua_journey_public_id: journeyPublicId,
        email: email,
      })
    )
  },
  activity: async (
    juaUserPublicId: string,
    kind: string,
    data: Object
  ): Promise<Activity> => {
    return await activitySchema.validate(
      await genericPostRequest(`jua_activities`, {
        jua_user_public_id: juaUserPublicId,
        kind: kind,
        data: data,
      })
    )
  },
  asset: async (juaUserPublicId: string, blob: Blob): Promise<Activity> => {
    const formData = new FormData()
    formData.append("jua_user_public_id", juaUserPublicId)
    formData.append("blob", blob)

    return await assetSchema.validate(
      await genericFormDataPostRequest(`jua_assets`, formData)
    )
  },
  bugReport: async (
    kind: string,
    deviceInfo: Object,
    message: string,
    tag: string
  ): Promise<BugReport> => {
    console.log("tag is", tag)
    return await bugReportSchema.validate(
      await genericPostRequest(`bug_reports`, {
        kind: kind,
        device_info: deviceInfo,
        message: message,
        tag: tag,
      })
    )
  },
}

export const destroy = {
  activity: async (juaActivityPublicId: string): Promise<object> => {
    return await successSchema.validate(
      await genericDeleteRequest(`jua_activities`, {
        jua_activity_public_id: juaActivityPublicId,
      })
    )
  },
}
