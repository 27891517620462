<template>
  <div>
    <transition name="fade">
      <div
        v-if="isUploading"
        class="absolute z-50 inset-0 bg-wg2-red flex items-center justify-center"
      >
        <div class="w-40 h-40 flex items-center justify-center overflow-hidden">
          <Vue3Lottie :animationData="WhiteSpinnerJSON" />
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div
        class="transition ease-in-out duration-500 fixed left-0 right-0 bottom-8"
        v-if="shown"
      >
        <!-- :class="isScrolling ? 'opacity-0' : 'opacity-100'" -->
        <div class="flex gap-x-4 justify-center items-end">
          <!-- <button
            id="show-snap"
            class="rounded-full bg-wg2-red h-14 w-14 text-white flex justify-center items-center transition-all active:scale-125"
            v-if="snapShown && store.getUser?.public_id"
          >
            <label for="cameraFileInput" class="custom-file-upload">
              <div
                class="w-12 h-12 border-2 border-white bg-wg2-red flex justify-center rounded-full items-center"
              >
                <span class="material-symbols-rounded">add_a_photo</span>
              </div>
            </label>

            <input
              id="cameraFileInput"
              type="file"
              accept="image/*"
              capture="environment"
              class="hidden"
              @change="handleSnap"
            />
          </button> -->
          <!-- <div class="h-14 w-14" v-else></div> -->
          <div class="h-14 w-14"></div>
          <button
            id="show-qr-code-reader"
            class="rounded-full bg-wg2-red h-14 w-14 text-white flex justify-center items-center transition-all ease-in-out duration-500 origin-bottom active:scale-150"
            :class="isScrollingDown ? 'scale-100' : 'scale-125 mx-5 '"
            @click="$emit('show-qr-code-reader')"
          >
            <div
              class="w-12 h-12 border-2 border-white bg-wg2-red flex justify-center rounded-full items-center"
            >
              <span class="material-symbols-rounded">qr_code</span>
            </div>
          </button>
          <button
            id="show-journey"
            class="rounded-full bg-wg2-red h-14 w-14 text-white flex justify-center items-center transition-all active:scale-125"
            @click="$emit('show-journey')"
            v-if="journeyShown && store.getUser?.public_id"
          >
            <div
              class="w-12 h-12 border-2 border-white bg-wg2-red flex justify-center rounded-full items-center"
            >
              <span class="material-symbols-rounded">perm_media</span>
            </div>
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import WhiteSpinnerJSON from "@/assets/projects/wg2/animations/white-spinner.json"
import { Vue3Lottie } from "vue3-lottie"

import { post } from "@/api/main"
import {
  UploadError,
  SelectionError,
  NoJourneyError,
} from "@/errors/user_capture"

const props = defineProps<{
  snapShown: boolean
  shown: boolean
  journeyShown: boolean
}>()

// const scrollTimer = ref(-1)
// const isScrolling = ref(false)
const isUploading = ref(false)

const store = useWg2Store()
const router = useRouter()

// const handleScroll = () => {
//   if (scrollTimer.value === -1) {
//     scrollTimer.value = window.setTimeout(() => {
//       isScrolling.value = false
//       scrollTimer.value = -1
//     }, 1000)
//   } else {
//     clearTimeout(scrollTimer)
//   }
// }

const redirect = () => {
  isUploading.value = false
  if (router.currentRoute.value.name == "wg2_journey") {
    router.go(0)
  } else {
    router.push({
      name: "wg2_journey",
      projectShortId: "wg2",
      force: true,
    })
  }
}

// const handleSnap = async (e: any) => {
//   window.scroll({ top: 0 })
//   isUploading.value = true
//   if (store.getUser && store.getJourney) {
//     const file = e?.target?.files[0]
//     if (file) {
//       const a = await post.asset(store.getUser.public_id, file)
//       if (a?.public_id) {
//         window.setTimeout(() => redirect(), 1000)
//       } else {
//         isUploading.value = false
//         throw new UploadError()
//       }
//     } else {
//       isUploading.value = false
//       throw new SelectionError()
//     }
//   } else {
//     isUploading.value = false
//     throw new NoJourneyError()
//   }
// }

const oldScrollY = ref(window?.scrollY || 0)
const isScrollingDown = ref(false)

onMounted(() => {
  window.addEventListener("scroll", () => {
    // isScrolling.value = true
    // handleScroll()
    if (oldScrollY.value < window.scrollY) {
      isScrollingDown.value = true
    } else {
      isScrollingDown.value = false
    }
    oldScrollY.value = window.scrollY
  })
})
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
